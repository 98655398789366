<template>
	<div class="tutorial-modal">
		<img :src="imgSrc" class="tutorial-modal__img" @click="tutorialHide">
	</div>
</template>

<script>

export default {
	name: 'TutorialModal',
	props: {
		imgSrc: {
			type: String,
			required: true
		}
	},
	methods: {
		tutorialHide () {
			this.$emit('tutorialClick')
		}
	}
}
</script>

<style lang="scss">
// 変数定義SASS
@import "@/assets/sass/variable";

.tutorial-modal {
	box-sizing: border-box;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: rgba($_black, .4);
	width: 100%;
	height: 100%;
	padding: $spacing-10;
	opacity: 1;
	z-index: 9999;
	&__img {
		width: auto;
		height: 100%;
	}
}
</style>
