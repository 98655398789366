<template>
	<div class="weather-graph">
		<!-- チュートリアル -->
		<transition name="fade">
			<tutorial-modal :imgSrc="`${frontUrl}${tutorialImg}`" v-if="isTutorial" @tutorialClick="clickHideTutorial" />
		</transition>
		<!-- /チュートリアル -->
		<common-error-alert @reload="setWeatherData" v-show="isNetworkErrorAlert" />
		<redraw-progress v-if="isLoading" />
		<ons-row class="weather-graph__information-bar" v-if="weatherDataList">
			<ons-col class="weather-graph__information-bar--left">
				<span class="weather-graph__year">{{ computedNowData.year }}</span><br>
				<span class="weather-graph__date">{{ computedNowData.month }}/{{ computedNowData.date }}</span><span class="weather-graph__weekday">{{ computedNowData.weekday }}</span>
			</ons-col>
			<ons-col class="weather-graph__information-bar--center" @click="clickResetTime">
				<span class="weather-graph__hour">{{ computedNowData.hour }}</span><span>時</span>
			</ons-col>
			<ons-col class="weather-graph__information-bar--right">
				<ons-row class="weather-graph__data">
					<ons-col class="weather-graph__data--label">
						気温<span class="is-small">(℃)</span>
					</ons-col>
					<ons-col class="weather-graph__data--value">
						{{ computedNowData.temperature }}
					</ons-col>
				</ons-row>
				<ons-row class="weather-graph__data">
					<ons-col>
						<ons-row>
							<ons-col class="weather-graph__data--label">
								気圧<span class="is-small">(hPa)</span>
							</ons-col>
							<ons-col class="weather-graph__data--value">
								{{ computedNowData.pressure }}
							</ons-col>
						</ons-row>
						<ons-row>
							<ons-col class="weather-graph__data--label">
								<span v-if="computedNowData.hour >= 3 && computedNowData.hour < 9"><span class="is-hour">3</span><span class="is-comma">-</span><span class="is-hour">9</span></span>
								<span v-else-if="computedNowData.hour >= 9 && computedNowData.hour < 15"><span class="is-hour">9</span><span class="is-comma">-</span><span class="is-hour">15</span></span>
								<span v-else-if="computedNowData.hour >= 15 && computedNowData.hour < 21"><span class="is-hour">15</span><span class="is-comma">-</span><span class="is-hour">21</span></span>
								<span v-else><span class="is-hour">21</span><span class="is-comma">-</span><span class="is-hour">3</span></span>
								<span class="is-small">時</span>
							</ons-col>
							<ons-col :class="[
								'weather-graph__data--value',
								computedNowData.pressure_diff_class
							]">
								{{ computedNowData.pressure_diff}}
							</ons-col>
						</ons-row>
					</ons-col>
				</ons-row>
			</ons-col>
		</ons-row>
		<ons-row class="weather-graph__information-bar" v-else></ons-row>
		<ons-row>
			<ons-col>
				<div id='js_mainArea' class="weather-graph__wrapper">
					<!-- NOTE: 上下左右に動くノード -->
					<div class="weather-graph__background" id="js_moveXY"></div>
					<!-- NOTE: Canvas -->
					<div class="weather-graph__canvas" id='js_mainCanvas'></div>
					<!-- NOTE: 左右に動くノード -->
					<div class="weather-graph__inner" id="js_moveX">
						<div class="weather-graph__date-ruler" id="js_dateRuler">
							<div class="weather-graph__date-ruler--mask" id="js_rulerMask"></div>
						</div>
						<div class="weather-graph__weather-ruler" id="js_weatherRuler"></div>
						<div class="weather-graph__current-line" id="js_currentLine"></div>
						<div class="weather-graph__pressure-warning" id="js_pressureWarning"></div>
					</div>
					<!-- NOTE: 上下に動くノード -->
					<div class="weather-graph__pressure" id="js_moveY">
						<ul class="weather-graph__pressure-list">
							<li v-for="n in 39" :key="n"  class="weather-graph__pressure-item">
								{{ pressureMax - (5 * n) }}
							</li>
						</ul>
						<img src="@/assets/img/graph_vertical_scale.svg" alt="気圧メモリ" class="weather-graph__pressure-scale">
					</div>
					<!-- NOTE: 固定のノード -->
					<i class="weather-graph__center-dot" />
					<i class="weather-graph__center-line" />
					<div class="weather-graph__character">
						<transition name="fade">
							<p
								:class="[
									'weather-graph__character--talk',
									{ 'H': classHiroshiAnimation === 'talk1' || classHiroshiAnimation === 'talk2' },
									{ 'M': classMaroAnimation === 'talk1' || classMaroAnimation === 'talk2' }
								]"
								v-show="characterTalkWords !== ''"
							>
								<span v-html="characterTalkWords" />
							</p>
						</transition>
						<i :class="['h_animation', classHiroshiAnimation]" />
						<transition name="fade">
							<p
								class="weather-graph__record--pain"
								@click="clickPenButton"
								@touchstart="classPenBtn = 'btn_pen_on'"
								@touchend="classPenBtn = 'btn_pen'"
								v-show="computedIsFuture"
							>
								<i :class="[
									'sprite-weathergraph',
									classPenBtn
								]" />
							</p>
						</transition>
						<i :class="['m_animation', classMaroAnimation]" />
					</div>
				</div>
			</ons-col>
		</ons-row>
		<!-- 痛み記録モーダル -->
		<pain-record :painRecordTime="painRecordTime" :painRecordItem="painRecordItem" @reload="setWeatherData(true)" ref="painRecord" />
		<!-- ポップアップ -->
		<v-ons-popover cancelable
			:visible.sync="isPopoverVisible"
			:target="'.popoup-bar'"
			:direction="'up'"
			:cover-target="false"
			class="popover-addhome"
		>
			<div class="popover-addhome__cancelBarArea" @click="isPopoverVisible = false">
				<i class="sprite-popoup btn_inp_x" />
			</div>
			<div class="popover-addhome__textArea">
				<i class="popover-addhome__textArea--img sprite-popoup app_icon" />
				<p class="popover-addhome__textArea--text">ホーム画面に追加しよう！<br><i v-show="!isAndroid" class="sprite-popoup share_icon" /><ons-icon v-show="isAndroid" class="android_share_icon" icon="ellipsis-v" />をタップ
				して<span class="popover-addhome__textArea--textWeight">「ホーム画面に追加」</span>で完了です。</p>
			</div>
		</v-ons-popover>
		<!-- プロフィール画面登録 -->
		<v-ons-modal :visible="isProfileModalVisible" animation="fade">
			<div class="profile-modal">
				<div class="profile-modal__box">
					<i
						:class="[
							'profile-modal__box--close',
							'profile-modal__sprite',
							classProfileModaleCloseBtn
						]"
						@touchstart="classProfileModaleCloseBtn = 'sprite-btn_inp_x_on'"
						@touchend="classProfileModaleCloseBtn = 'sprite-btn_inp_x'"
						@click="isProfileModalVisible = !isProfileModalVisible"
					/>
					<p class="profile-modal__box--title">ようこそ<br>痛みノートへ</p>
					<span class="profile-modal__sprite sprite-note_img" />
					<p class="profile-modal__box--text">痛みの度合いや、クスリを飲んだタイミングを"痛みノート"に記録して自分の身体と気圧の関係を知ろう。</p>
					<span
						:class="[
							'profile-modal__sprite',
							classProfileBtn
						]"
						@touchstart="classProfileBtn = 'sprite-btn_note_on'"
						@touchend="classProfileBtn = 'sprite-btn_note'"
						@click="clickProfileRegistButton"
					/>
				</div>
			</div>
		</v-ons-modal>
		<!-- プロフィール登録完了ダイアログ -->
		<v-ons-alert-dialog
			modifier="rowfooter"
			:title="'ありがとうございます'"
			:footer="{
				OK: () => clickProfileComplate()
			}"
			:visible.sync="isProfileComplateVisible"
		>
			プロフィールの設定が終わりました。<br>"痛みノート"に体調を記録してみましょう。
		</v-ons-alert-dialog>

		<!-- キャラクターアニメーション用スプライト画像のプリロード -->
		<!-- NOTE: デフォルトで画像にハッシュが付くため（例： sprite_m_normal1.1886a75b.png)、CSSのbackgroundに指定した画像はオーソドックスなプリロードができない。ハッシュを付けないようVueCliの設定が必要だがリスクが高いため、一度隠しノードでレンダリングしてプリロードするようにした。 -->
		<div style="opacity: 0; height: 0; overflow: hidden;" id="js_preloadCharacter">
			<i class="h_animation normal1" />
			<i class="m_animation normal1" />
			<i class="h_animation talk1" />
			<i class="m_animation talk1" />
			<i class="h_animation talk2" />
			<i class="m_animation talk2" />
		</div>
	</div>
</template>

<script>
import * as PIXI from 'pixi.js'
import cmnConst from '@/assets/js/constant.js'

// Compornents
import CommonErrorAlert from '../components/Molecules/CommonErrorAlert'
import RedrawProgress from '../components/Molecules/RedrawProgress'
import PainRecord from '../components/Organisms/PainRecord'
import TutorialModal from '../components/Molecules/TutorialModal'

// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_WEATHER_DATA, SET_RECORD_DATA, SET_RECORD_DATA_WHOLEPERIOD, SET_TALK_DATA } from '../store/modules/weatherGraph/mutation-types'
import { SET_CITY_NAME, SET_USER_DATA, SET_PROFILE_TURORIAL_FLAG, SET_GRAPH_DATE, SET_VISIBLE_POPUP_TYPE } from '../store/modules/common/mutation-types'

// NOTE: momentの宣言はapp.vueなどで共通化できるかもしれないので、ここで宣言するか要検討
import moment from 'moment'
moment.locale('ja')

// 定数
const RULER_HEIGHT = 30	// 日付ルーラーまたは天気ルーラーの高さ
const CELL_HEIGHT = 16
const INFOMATION_HEIGHT = 60	// インフォメーションバーの高さ
const SUGOTOKU_GRAPH_HEIGHT_RATIO = 1.493333333333333

export default {
	name: 'WeatherGraph',
	components: {
		CommonErrorAlert,
		RedrawProgress,
		PainRecord,
		TutorialModal
	},
	data () {
		return {
			weatherDataList: null,
			fromDay: -14,
			toDay: 7,
			pixiApp: null,
			loader: null,
			stageWidth: null,
			stageHeight: null,
			widthHour: null,	// 日時バーの時間メモリ横幅
			totalHour: null,
			nowUnixTime: null,
			containerGraph: null,
			containerPain: null,
			isLoading: false,
			isNetworkErrorAlert: false,
			isRecordDateCheckFlag: false,
			isProfileModalVisible: false,
			isProfileComplateVisible: false,
			isPopoverVisible: false,
			isAnimation: false,
			isTutorial: false,
			classPenBtn: 'btn_pen',
			classProfileModaleCloseBtn: 'sprite-btn_inp_x',
			classProfileBtn: 'sprite-btn_note',
			classHiroshiAnimation: 'normal1',
			classMaroAnimation: 'normal1',
			eventDetector: null,
			domMainArea: null,
			domGraphBg: null,
			domMoveX: null,
			domMoveY: null,
			domCurrentLine: null,
			posCenterX: 0,
			posCurrentX: 0,
			posCanvasStartX: 0,
			posBgStartX: 0,
			posMoveXStartX: 0,
			timmerId: null,
			currentDate: null,
			painRecordTime: '', // 記録の日時
			painRecordItem: null, // 痛み記録データの対象アイテム
			tutorialKey: null,
			tutorialImg: null,
			pressureMax: 1085,	// NOTE: 最大気圧値。templateでも使用するので定数ではなくdataで管理。
			frontUrl: cmnConst.FRONT_URL,	// スゴ得用フロントサーバURL（スマパスは空文字）
			characterTalkWords: ''
		}
	},

	mounted () {
		// NOTE: ユーザー情報をチェック
		if (!this.userData) {
			this.SET_USER_DATA({
				user_id: this.userId,
				user_token: this.userToken
			}).then(res => {
				console.log('userData: ', this.userData)
				const cityCode = this.userData.city_code
				if (!cityCode || cityCode === '') {
					// CHANGED: 地点登録ページから地点登録チュートリアルページに変更
					// 地点登録がされていない場合、地点登録チュートリアルページへ
					if (this.viewMode === 'sugotoku') {
						this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=pointTutorial` } })
					} else {
						this.$router.push('/pointTutorial')
					}
				} else {
					this.start()
				}
			}).catch(error => {
				console.log('Error SET_USER_DATA: ', error)
				// NOTE: ユーザー情報が取れない=グラフ描画APIが叩けないので再読み込み画面を表示するのではなく、エラーページに遷移させる
				if (this.viewMode === 'sugotoku') {
					this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=error`, type: 'user' } })
				} else {
					this.$router.push({ path: '/error', query: { type: 'user' } })
				}
			})
		} else {
			const cityCode = this.userData.city_code
			if (!cityCode || cityCode === '') {
				// CHANGED: 地点登録ページから地点登録チュートリアルページに変更
				// ユーザー情報があっても、地点登録がされていない場合（地点登録せずにアプリ終了やページ遷移した場合）は地点登録チュートリアルページへ
				if (this.viewMode === 'sugotoku') {
					this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=pointTutorial` } })
				} else {
					this.$router.push('/pointTutorial')
				}
				// this.$router.push({ path: '/pointRegistration', query: { isFirst: true } })
			} else {
				this.start()
			}
		}

		// キャラクタースプライト画像のプリロードノードを削除
		const domPreloadCharacter = document.getElementById('js_preloadCharacter')
		domPreloadCharacter.parentNode.removeChild(domPreloadCharacter)
	},

	computed: {
		// map Vuex getters
		...mapGetters('weatherGraph', ['weatherData', 'recordeData', 'recordeDataWholePeriod', 'talkData']),
		...mapGetters('common', ['userId', 'userToken', 'userData', 'profileTutorialFlag', 'graphDate', 'viewMode', 'isAndroid']),

		/**
		 * x軸の座標計算
		 */
		nowSec () {
			return this.containerGraph && this.widthHour ? Math.floor(-(this.containerGraph.x / this.widthHour)) : null
		},

		/**
		 * 現在表示しているweatherDataListのアイテムindex
		 */
		indexX () {
			return this.nowSec < 0 ? 0 : this.nowSec < this.totalHour - 1 ? this.nowSec : this.totalHour - 1
		},

		/**
		 * x軸の座標計算（気圧折れ線グラフ）
		 */
		nowSecDecimal () {
			return this.containerGraph && this.widthHour ? Math.abs(this.containerGraph.x / this.widthHour) - Math.trunc(Math.abs(this.containerGraph.x / this.widthHour)) : null
		},

		/**
		 * 現在の選択データ
		 */
		computedNowData () {
			return this.weatherDataList && this.indexX >= 0 ? this.weatherDataList[this.indexX] : null
		},

		/**
		 * 現在の選択データが未来を超えたかどうかの処理
		 */
		computedIsFuture () {
			// NOTE: computed内でmoment()を呼ぶのはパフォーマンスを下げる可能性があるので、現在日時のunix timeはデータ取得時にthis.nowUnixTimeに格納しておく。
			return !(this.weatherDataList && this.posCurrentX !== 0 && this.weatherDataList[this.indexX].unix_hour > this.nowUnixTime)
		}
	},

	methods: {
		// map Vuex actions
		...mapActions('weatherGraph', [SET_WEATHER_DATA, 'updateRecordData', 'deleteRecordData', SET_RECORD_DATA, SET_RECORD_DATA_WHOLEPERIOD, SET_TALK_DATA]),
		...mapActions('common', [SET_CITY_NAME, SET_USER_DATA, SET_PROFILE_TURORIAL_FLAG, SET_GRAPH_DATE, SET_VISIBLE_POPUP_TYPE]),

		/**
		 * グラフの描画開始処理
		 */
		start () {
			// TODO: startメソッドの中の処理は煩雑にならないように、別にメソッドを定義して綺麗にまとめた方がよいかも（if文の処理など）
			if (localStorage.getItem(cmnConst.GRAPH_TUTORIAL_KEY) === 'show') {
				this.isTutorial = true
				this.tutorialKey = cmnConst.GRAPH_TUTORIAL_KEY
				this.tutorialImg = cmnConst.GRAPH_TUTORIAL_IMG
			}

			// TODO: リリース後にリファクタリング予定
			// NOTE: profileTutorialFlagがtrueの時にチュートリアルを表示する
			if (this.userData.register_flg && this.profileTutorialFlag) {
				this.isProfileComplateVisible = true
			} else {
				this.SET_PROFILE_TURORIAL_FLAG(false)
			}

			// NOTE: 14 => 初期表示は14日前まで表示, 7 => 初期表示は7日後まで表示
			// NOTE: 痛みリストからの遷移での判定
			// CHANGED: 2020/04/24 痛みリストからの遷移のパラメータをYYYY-MM-DD HH:mm:ssの文字列に変更
			// CHANGED: 2020/04/24 期間範囲の基準をtoDay、toFromからcurrentDateに変更
			if (this.$route.params.currentDate) {
				this.currentDate = moment(this.$route.params.currentDate, 'YYYY-MM-DD HH:mm:ss')
				this.toDay = 14
			} else if (this.graphDate) {
				// NOTE: プロフィール登録モーダルからプロフィール登録してrouter.backしてきた場合
				this.currentDate = moment.unix(this.graphDate.hour, 'YYYY-MM-DD HH:mm:ss')
				this.toDay = 14
			} else {
				this.currentDate = moment()
			}

			this.initGraphSize()
			this.initCanvas()
			this.preload().then(res => {
				this.setWeatherData()
			}).catch(error => {
				this.isNetworkErrorAlert = true
				console.log('Error preload: ', error)
			})

			// スゴ得の場合、コンテンツの高さをemitする。
			const domContainer = document.getElementById('js_container')
			if (this.viewMode === 'sugotoku' && domContainer) {
				this.$emit('containerHeight', domContainer.clientHeight)
			}
		},

		/**
		 * 画像のプリロード処理
		 */
		preload () {
			return new Promise((resolve, reject) => {
				this.loader = new PIXI.Loader()
				// NOTE: スゴ得は絶対パスでCPサーバの画像を読む必要があるためpublic配下の画像を読む場合は絶対パスに変更する
				this.loader.add('sprite', `${this.frontUrl}static/weathergraph/sprite_weathergraph_pixiJs.json`)
				this.loader.load((loader, resources) => {
					resolve('OK')
				})
				this.loader.onError.add((e) => {
					console.log('Error loader.onError: ', e)
					reject(e)
				})
			})
		},

		/**
		 * グラフサイズの初期化処理
		 */
		initGraphSize () {
			this.domMainArea = document.getElementById('js_mainArea')
			this.domGraphBg = document.getElementById('js_moveXY')
			this.domMoveX = document.getElementById('js_moveX')
			this.domMoveY = document.getElementById('js_moveY')
			this.domCurrentLine = document.getElementById('js_currentLine')

			if (window.innerWidth < cmnConst.INIT_STAGE_WIDTH) {
				// NOTE: メイン描画領域は画面サイズによって「height」を変更する
				let height = window.innerHeight < cmnConst.INIT_STAGE_HEIGHT ? window.innerHeight : cmnConst.INIT_STAGE_HEIGHT
				if (this.viewMode !== 'sugotoku') {
					height = height - cmnConst.HEADER_HEIGHT - cmnConst.FOOTER_HEIGHT - INFOMATION_HEIGHT	// 元の高さ - ヘッダーの高さ - フッターの高さ - インフォメーションバーの高さ
				} else {
					height = (window.innerWidth * SUGOTOKU_GRAPH_HEIGHT_RATIO) - INFOMATION_HEIGHT
				}
				this.domMainArea.setAttribute('style', `height: ${height}px;`)
				// 動かすノードの初期X位置設定
				this.initStartX()
			} else {
				// 動かすノードの初期X位置設定
				this.initStartX()
			}
		},

		/**
		 * 背景ノードX位置、X移動ノードX位置の初期化処理
		 */
		initStartX () {
			this.posBgStartX = -7360	// -(背景画像横幅(15360px) / 2) + (w640 / 2)
			this.posMoveXStartX = -6848	// -(グラフ領域横幅(14336px) / 2) + (w640 / 2)

			if (window.innerWidth < cmnConst.INIT_STAGE_WIDTH) {
				const scale = window.innerWidth / cmnConst.INIT_STAGE_WIDTH

				// NOTE: 背景ノードは画面サイズによって「scaleX」「left」を変更する
				this.posBgStartX = this.posBgStartX * scale
				this.domGraphBg.setAttribute('style', `transform: scaleX(${scale}); left: ${this.posBgStartX}px;`)

				// NOTE: X移動ノードは画面サイズによって「width」「left」を変更する
				const widthMoveX = ((window.innerWidth / 30) * 24) * 28	// (w640 / 30(1画面に入る時間)) * 24h * 28day
				this.posMoveXStartX = -(widthMoveX / 2) + (window.innerWidth / 2)	// -(グラフ領域横幅(14336px) / 2) + (w640 / 2)
				this.domMoveX.setAttribute('style', `width: ${widthMoveX}px; left: ${this.posMoveXStartX}px;`)
			} else {
				this.domGraphBg.setAttribute('style', `left: ${this.posBgStartX}px;`)
				this.domMoveX.setAttribute('style', `left: ${this.posMoveXStartX}px;`)
			}
		},

		/**
		 * Canvasの初期化処理
		 */
		initCanvas () {
			const mainCanvas = document.getElementById('js_mainCanvas')
			let resolution = 1
			if (window.devicePixelRatio) resolution = devicePixelRatio
			this.stageWidth = window.innerWidth < cmnConst.INIT_STAGE_WIDTH ? window.innerWidth : cmnConst.INIT_STAGE_WIDTH
			const height = window.innerHeight < cmnConst.INIT_STAGE_HEIGHT ? window.innerHeight : cmnConst.INIT_STAGE_HEIGHT
			// スゴ得とスマパスで表示を分ける
			if (this.viewMode !== 'sugotoku') {
				// NOTE: canvasの高さ - ヘッダーの高さ - フッターの高さ - インフォメーションエリアの高さ - 日付バーの高さ
				this.stageHeight = height - cmnConst.HEADER_HEIGHT - cmnConst.FOOTER_HEIGHT - INFOMATION_HEIGHT - RULER_HEIGHT
			} else {
				this.stageHeight = (this.stageWidth * SUGOTOKU_GRAPH_HEIGHT_RATIO) - INFOMATION_HEIGHT - RULER_HEIGHT
			}
			// NOTE: 日時ルーラーのメモリ横幅（1画面に30時間分入る）
			this.widthHour = this.stageWidth / 30
			this.pixiApp = new PIXI.Application({
				autoStart: false,
				width: this.stageWidth,
				height: this.stageHeight,
				resolution: resolution,
				forceCanvas: false,
				autoDensity: true,
				transparent: true,
				antialias: true
			})
			mainCanvas.appendChild(this.pixiApp.view)

			// Tickerの設定
			this.pixiApp.ticker.maxFPS = 60
			this.pixiApp.ticker.add(this.loop)
			this.pixiApp.stage.interactive = true
		},

		/**
		 * コンテナーの初期化
		 */
		initStage () {
			this.posCenterX = -this.widthHour * 336	// 1時間のメモリ横幅 * 2週間分の時間
			// 気圧折れ線グラフ
			this.containerGraph = new PIXI.Container()
			this.containerGraph.x = this.posCenterX
			this.containerGraph.y = RULER_HEIGHT
			this.pixiApp.stage.addChild(this.containerGraph)

			// 痛みアイコン
			this.containerPain = new PIXI.Container()
			this.containerPain.x = this.posCenterX
			this.containerPain.y = this.stageHeight - 150	// ステージの高さ - 痛み記録領域高さ
			this.pixiApp.stage.addChild(this.containerPain)

			// グラフ背景のX軸を日時ルーラーに合わせる
			const posBgX = this.removePx(this.domGraphBg.style.left)
			this.posBgStartX = posBgX + -((this.weatherDataList[0].hour % 24) * this.widthHour)	// 調整した位置 + -(現在時刻/24の余り) * 1時間のメモリ幅
			this.domGraphBg.style.left = `${this.posBgStartX}px`

			// 現在時刻線
			const diff = moment(this.currentDate).diff(moment(), 'days')
			if (diff >= -14) {	// 現在時刻線を表示するケース
				let posDiffHours = moment().diff(moment(this.currentDate), 'hours') * this.widthHour	// 現在時刻とカレント日時の時間差分*時間メモリの横幅
				if (posDiffHours < 0) posDiffHours += -this.widthHour	// -値の場合、1メモリずれるので-1メモリする
				posDiffHours += this.widthHour * 24 * 14	// メモリ横幅*2週間分を加算
				this.domCurrentLine.style.left = `${posDiffHours}px`
				this.domCurrentLine.style.display = 'block'
				this.posCurrentX = -posDiffHours
			} else {	// 現在時刻線を表示しないケース
				this.domCurrentLine.style.display = 'none'
				this.posCurrentX = 0
			}

			// イベントの登録
			if (!this.eventDetector) this.initStageEvent()
		},

		/**
		 * イベントの登録処理
		 */
		initStageEvent () {
			this.eventDetector = this.$ons.GestureDetector(this.domMainArea)

			// NOTE: drag系のイベント使う（swipeだとChromeのプレビューで動かなく実機で若干スムーズではなくなる）
			this.eventDetector.on('dragstart', (e) => {
				// 慣性スクロール中にスワイプがあった場合、慣性スクロールを止める
				this.stopInertiaScroll()
				this.moveStart()
				this.isAnimation = true
				this.pixiApp.start()
			})

			this.eventDetector.on('dragend', (e) => {
				this.inertiaScroll(e)
			})

			this.eventDetector.on('dragleft', (e) => {
				this.moveX(e)
			})

			this.eventDetector.on('dragright', (e) => {
				this.moveX(e)
			})
		},

		/**
		 * 慣性スクロール
		 */
		inertiaScroll (e) {
			/**
			* NOTE: 実機確認結果（それぞれの値の設定により多少異なる）
			*  friction = 0.92-0.94[CPU:40~60%], 0.95-0.96[CPU:50~60%], 0.98[CPU:70~80%]
			*  accelX = 0.3-0.5[CPU:60~80%], 3[CPU:50~60%]
			*  setInterval = 10[CPU:70%], 20[CPU:60~70%], 30[CPU:60%], 40-50[CPU:40~50%] ※数字が大きくなるごとに動作がカクツク
			*/
			const friction = 0.96
			const swipeRegulation = 10
			const swipeThreshold = 10
			let accelX = 0
			let deltaX = e.gesture.deltaX
			const horSwipeDistancePer = Math.abs((deltaX / this.stageWidth) * 100)
			const deltaTime = e.gesture.deltaTime
			// NOTE: アニメーションを止める関数
			const stopAnimation = () => {
				this.isAnimation = false
				this.scalePainIcon(true)	// 痛みアイコンがカレントにきたときの処理
				this.pixiApp.stop()
			}

			if (deltaTime > 300) deltaX /= 50
			accelX = deltaX / swipeRegulation
			if (horSwipeDistancePer > swipeThreshold && !this.isLoading) {
				this.timmerId = setInterval(() => {
					this.moveStart()
					accelX *= friction
					this.moveX({ gesture: { deltaX: accelX, direction: e.gesture.direction } })
					if (Math.abs(accelX) <= 3) {
						stopAnimation()
						this.stopInertiaScroll()
					}
				}, 30)
			} else {
				stopAnimation()
			}
		},

		/**
		 * 慣性スクロールの停止
		 */
		stopInertiaScroll () {
			if (this.timmerId) {
				clearInterval(this.timmerId)
				this.timmerId = null
			}
		},

		/**
		 * スワイプ開始処理
		 */
		moveStart () {
			this.posCanvasStartX = this.containerGraph.x
			this.posBgStartX = this.removePx(this.domGraphBg.style.left)
			this.posMoveXStartX = this.removePx(this.domMoveX.style.left)
		},

		/**
		 * スワイプ時のx軸の描画
		 */
		moveX (e) {
			// X軸の移動を描画しないパターン
			if (this.isLoading) return false
			if (e.gesture.direction === 'left' && this.indexX === this.totalHour - 1) {
				this.stopInertiaScroll()
				if (this.toDay === 14) {
					this.redraw(false)
				}
				return false
			}
			if (e.gesture.direction === 'right' && this.indexX === 0) {
				this.stopInertiaScroll()
				this.redraw(false)
				return false
			}

			// Canvasの移動
			let x = e.gesture.deltaX + this.posCanvasStartX
			this.containerGraph.x = x
			this.containerPain.x = x
			this.scalePainIcon()	// 痛みアイコンがカレントにきたときの処理

			// DOMの移動
			x = e.gesture.deltaX + this.posBgStartX
			this.domGraphBg.style.left = `${x}px`
			x = e.gesture.deltaX + this.posMoveXStartX
			this.domMoveX.style.left = `${x}px`

			// Y軸の移動
			this.moveY()
		},

		/**
		 * 痛みアイコンがカレントにきたときの処理
		 *
	 	 * @param {Boolean} isStop 完全停止時の処理かどうかのフラグ
		 */
		scalePainIcon (isStop) {
			const defaultSize = 30
			const scaleSize = 35
			const stopScaleSize = 50
			let isChangeZindex = false
			// カレントにアイコンがある、または拡大されたアイコンがある場合に処理する
			const isCurrentPainIcon = this.containerPain.children.some(item => item.name === this.indexX || item.width === scaleSize || item.width === stopScaleSize)
			if (isCurrentPainIcon) {
				// console.log('containerPain:', this.containerPain)
				// console.log('weatherDataList: ', this.weatherDataList[this.indexX])
				// console.log('indexX: ', this.indexX)
				// NOTE: ループ系はforが一番早いらしい
				for (let i = 0, len = this.containerPain.children.length; i < len; i++) {
					const item = this.containerPain.children[i]
					switch (true) {
						case item.name === this.indexX :	// nameに格納されたindexがカレントの場合はアイコンを拡大する
							if (isStop) {	// 完全にスクロールが止まったときの処理
								const timmerId = setInterval(() => {
									// if (!this.pixiApp.ticker.started) this.pixiApp.start()	// Tickerが止まっていたら開始する
									item.width = item.width + 1
									item.height = item.height + 1
									switch (true) {
										case item.width >= stopScaleSize :	// アイコン拡大が終わったらインターバル終了
											clearInterval(timmerId)
											// if (!this.isAnimation) this.pixiApp.stop()
											break
										case this.isAnimation :	// アイコン拡大アニメーション時にスクロールされたら元に戻してインターバル終了
											item.width = scaleSize
											item.height = scaleSize
											clearInterval(timmerId)
											break
									}
									// NOTE: 非同期処理でTickerを止めたり動かしたりするとスクロールのタイミングによってはCanvasの描画が止まったままスクロールしてしまう。そのためTickerが止まっていてもPixiJsのレンダリングを行うようにした。
									this.pixiApp.renderer.render(this.pixiApp.stage)
								}, 5)
							} else if (item.width === defaultSize) {	// スクロール中の処理でアイコンサイズがデフォルトの場合
								item.width = scaleSize
								item.height = scaleSize
								item.zIndex = 1
								isChangeZindex = true
							}
							break
						case item.width === scaleSize || item.width === stopScaleSize :	// カレント以外で拡大されたアイコンの場合は元に戻す
							item.width = defaultSize
							item.height = defaultSize
							item.zIndex = 0
							isChangeZindex = true
							break
					}
				}
				// z-indexの更新があれば親コンテナで子のz-indexソートをかける
				if (isChangeZindex) this.containerPain.sortChildren()
			}
		},

		/**
		 * スワイプ時のy軸の描画
		 */
		moveY () {
			this.SET_CITY_NAME(this.weatherDataList[this.indexX].city_name)	// NOTE: ヘッダーに表示する地点名を登録
			const y = Number(this.weatherDataList[this.indexX].pressure)
			const index = this.nowSec < this.totalHour - 1 ? this.indexX : this.indexX - 1
			const pressureGraphYPoint = y + ((this.weatherDataList[index + 1].pressure - this.weatherDataList[index].pressure) * this.nowSecDecimal)	// 気圧 + (次の気圧と今の気圧の気圧差 * X軸中心の小数点)

			// NOTE: 気圧情報がないケースはY軸移動しない
			if (!isNaN(pressureGraphYPoint)) {
				// Canvasの移動
				this.containerGraph.y = (-(this.pressureMax - pressureGraphYPoint) * CELL_HEIGHT) + (this.stageHeight / 2)

				// DOMの移動
				this.domGraphBg.style.top = `${134 + (CELL_HEIGHT / 2) + (-(this.pressureMax - y) * CELL_HEIGHT) + (this.stageHeight / 2)}px`	// ヘッダー・インフォメーションバー・日時ルーラーの高さ + (セルの高さ / 2) + (-(気圧最大値と気圧の差) * セルの高さ) + ステージ高さの半分
				this.domMoveY.style.top = `${30 + (-(this.pressureMax - y) * CELL_HEIGHT) + (this.stageHeight / 2)}px`	// 日時ルーラーの高さ + (-(気圧最大値と気圧の差) * セルの高さ) + ステージ高さの半分
			}

			// NOTE: タイミングによってはTickerが止まっている場合がある、Tickerが止まっていてもこのメソッドが呼ばれたらPixiJsのレンダリングを行う
			this.pixiApp.renderer.render(this.pixiApp.stage)
		},

		/**
		 * 再描画処理
		 * 未来日は14日分、未来日は最大7日間まで閲覧可能。
	 	 * @param {Boolean} isReset resetかどうかのフラグ
		 */
		redraw (isReset) {
			if (isReset) {
				this.currentDate = moment()
				this.toDay = 7
			} else {
				// 次のカレント日時を設定
				const nextCurrentItem = this.weatherDataList[this.indexX]
				const month = nextCurrentItem.month > 9 ? nextCurrentItem.month : '0' + nextCurrentItem.month
				const date = nextCurrentItem.date > 9 ? nextCurrentItem.date : '0' + nextCurrentItem.date
				const hour = nextCurrentItem.hour > 9 ? nextCurrentItem.hour : '0' + nextCurrentItem.hour
				const nextCurrentDate = moment(`${nextCurrentItem.year}-${month}-${date} ${hour}:00:00`, 'YYYY-MM-DD HH:mm:ss')

				// 次の未来日と現在日時＋7日間の差分
				const diff = moment(nextCurrentDate).add(14, 'days').diff(moment().add(7, 'days'), 'days')
				// NOTE：差分が7日間を超えた場合、すでにすべてのデータが描画されているので再描画しない。
				if (diff > 7) {
					// toDayとカレント日時をデフォルトに戻す
					this.toDay = 7
					// this.currentDate = moment()
					return false
				} else {
					this.toDay = 14
					this.currentDate = nextCurrentDate
				}
			}

			// 現在時刻線をいったん非表示
			this.domCurrentLine.style.display = 'none'

			// Canvasの初期化
			this.pixiApp.stop()
			this.pixiApp.stage.removeChildren()

			// DOMの削除
			let dom = document.getElementById('js_dateRuler')
			while (dom.firstChild) dom.removeChild(dom.firstChild)
			dom.insertAdjacentHTML('afterbegin', `<div class="weather-graph__date-ruler--mask" id="js_rulerMask"></div>`)
			dom = document.getElementById('js_weatherRuler')
			while (dom.firstChild) dom.removeChild(dom.firstChild)
			dom = document.getElementById('js_pressureWarning')
			while (dom.firstChild) dom.removeChild(dom.firstChild)

			// 位置の初期化
			this.initStartX()

			// 再描画
			this.setWeatherData()
		},

		/**
		 * 痛みアイコンの再描画処理
		 */
		redrawPainIcon () {
			// 痛みアイコンの初期化
			this.containerPain.removeChildren()

			const list = this.weatherDataList	// オブジェクトをローカル変数に格納
			for (let i = 0, len = list.length; i < len; i++) {
				// 痛みアイコンの描画
				if (list[i].pain) this.drawPainIcon(list[i], i)
			}

			// PixiJsのレンダリング
			this.pixiApp.start()
			this.pixiApp.renderer.render(this.pixiApp.stage)
			this.pixiApp.stop()

			// 痛みアイコンの拡大
			this.scalePainIcon(true)
		},

		/**
		 * Tickerの描画処理
		 */
		loop () {
			this.pixiApp.renderer.render(this.pixiApp.stage)
			// CHANGE: 不要な処理なのでコメントアウト
			// if (this.isAnimation) this.pixiApp.renderer.render(this.pixiApp.stage)
		},

		/**
		 * 気圧グラフ描画メイン（weatherDataListのループ）処理
		 */
		drawWeathrGraph () {
			const list = this.weatherDataList	// オブジェクトをローカル変数に格納
			const pressureGraphics = new PIXI.Graphics()	// 気圧グラフ描画用グラフィック

			// ループ前処理
			const lineHeight = 2
			pressureGraphics.lineStyle(lineHeight, '0x55b1ff')
			const pressureWarningHeight = this.stageHeight / 2
			const pressureWarningScale = window.innerWidth / 5 / 180 // NOTE: 気圧警戒アラートの実横幅 / 気圧警戒アラートの画像横幅
			const pressureWarningTop = (this.stageHeight - (1050 * pressureWarningScale)) / 2 // NOTE: 1050 = 気圧警戒アラートの画像縦幅
			const pressureWarningHtml = []
			const weatherRulerHtml = []

			// NOTE：3時、9時、15時、21時が気圧レベル表示箇所なので、時間帯によってはその時間とそれ以外の時間で気圧レベルノード数が変わってしまう。以下はそのノード数を合わせるための変数・定数。
			let countPessureWarning = 0
			const MAX_PRESSURE_WARNING = Math.round(list.length / 6)

			// NOTE： ループ処理をまとめて行う
			for (let i = 0, len = list.length; i < len; i++) {
				const item = list[i]

				// 気圧折れ線グラフの描画
				const lineY = ((this.pressureMax - item.pressure) * CELL_HEIGHT) + lineHeight
				if (i === 0) pressureGraphics.moveTo(this.stageWidth / 2, lineY)
				pressureGraphics.lineTo((i * this.widthHour) + (this.stageWidth / 2), lineY)

				// 痛みアイコンの描画
				if (item.pain) this.drawPainIcon(item, i)

				// 気圧警戒アラートの実装
				if (i === 0) {
					// NOTE: 読込時の表示時刻Hour / 6 のあまりが 1 = 右に2時間分, 2 = 右に1時間分, 3 = 右に0時間分ずれる, 4 = 右に5時間分ずれる, 5 = 右に4時間分ずれる, 6 = 右に3時間分ずれる | 1時間あたり約3.3vw
					let pressureDiff = (Number(this.weatherDataList[this.indexX].hour) + 2) % 6
					pressureDiff = (5 - pressureDiff) * 10 / 3
					pressureWarningHtml.push(`<ul class="weather-graph__pressure-warning-list" style="left:${pressureDiff}vw;">`)
				}
				// 3時、9時、15時、21時を基点に気圧レベル表示
				// NOTE: 上記時間とそれ以外でノード数が変わるので合わせる（3時、9時、15時、21時は最後のノードは不要なので追加しない）
				if (item.hour % 6 === 3 && countPessureWarning < MAX_PRESSURE_WARNING) {
					const pressureWarningItem = item.pressure_level
					pressureWarningHtml.push(`<li class="weather-graph__pressure-warning-item" style="height:${pressureWarningHeight}px">
						<span class="weather-graph__pressure-warning-area sprite-weathergraph warning_area${pressureWarningItem}" style="top:${pressureWarningTop}px; -webkit-transform:translateY(-50%) translateX(-50%) scale(${pressureWarningScale}); transform:translateY(-50%) translateX(-50%) scale(${pressureWarningScale});"></span>
							<i class="weather-graph__pressure-warning-icon sprite-weathergraph warning_icon${pressureWarningItem}" style="-webkit-transform:translateY(-50%) translateX(-50%) scale(${pressureWarningScale}); transform:translateY(-50%) translateX(-50%) scale(${pressureWarningScale});"></i>
						</li>`)
					countPessureWarning++
				}
				if (i === len - 1) pressureWarningHtml.push(`</ul>`)

				// 天気ルーラーの実装
				if (i === 0) {
					// NOTE: 読込時の表示時刻Hour / 3 のあまりが 1 = 右に0.5時間分, 2 = 右に-0.5時間分, 0 = 右に-1.5時間分ずれる | 1時間あたり約3.3vw
					let weatherDiff = (Number(this.weatherDataList[this.indexX].hour) + 2) % 3
					weatherDiff = (-2 * weatherDiff + 1) * 5 / 3
					weatherRulerHtml.push(`<ul class="weather-graph__weather-ruler-list" style="left:${weatherDiff}vw;">`)
				}
				if (item.hour % 3 === 0) {
					const weatherDateItem = Number(moment.unix(item.unix_hour).format('YYYYMMDDHH')) < Number(moment().format('YYYYMMDDHH')) ? 'is-past' : 'is-future'
					const temperatureItem = Math.floor(item.temperature)
					const haremaxFlg = temperatureItem >= 35
					const haremoonFlg = (item.hour >= 18 || item.hour <= 3)
					let codeItem = item.weather_code === '1' && haremaxFlg === false && haremoonFlg === false ? 'hare'
						: item.weather_code === '1' && haremaxFlg === true && haremoonFlg === false ? 'haremax'
							: item.weather_code === '1' && haremoonFlg === true ? 'haremoon'
								: item.weather_code === '2' ? 'kumori'
									: item.weather_code === '3' ? 'ame'
										: 'yuki'
					codeItem = weatherDateItem === 'is-future' ? codeItem : `${codeItem}_old`
					weatherRulerHtml.push(`<li class="weather-graph__weather-ruler-item">
						<span class="weather-graph__weather-ruler-temperature ${weatherDateItem}">${temperatureItem}℃</span>
							<i class="weather-graph__weather-ruler-code sprite-weathergraph wicon_${codeItem}"></i>
						</li>`)
				}
				if (i === len - 1) weatherRulerHtml.push(`</ul>`)
			}
			const pressureWarningElem = document.getElementById('js_pressureWarning')
			pressureWarningElem.insertAdjacentHTML('afterbegin', pressureWarningHtml.join(''))
			const weatherRulerElem = document.getElementById('js_weatherRuler')
			weatherRulerElem.insertAdjacentHTML('afterbegin', weatherRulerHtml.join(''))

			// ループ後処理
			this.containerGraph.addChild(pressureGraphics)
			this.moveY()

			// PixiJsのレンダリング
			this.pixiApp.start()
			this.pixiApp.renderer.render(this.pixiApp.stage)
			this.pixiApp.stop()

			// 痛みアイコンの拡大
			this.scalePainIcon(true)
		},

		/**
		 * 日付ルーラー処理
		 */
		initDateRuler () {
			const dateWidth = this.widthHour * 24 // this.widthHour * 24時間
			const hourMargin = dateWidth / 24 - 1 // dateWidth / 24時間 - メモリサイズ

			const dateHtml = []
			let dayCount = this.fromDay

			const hour = this.weatherDataList[0].hour
			let startPoint = hour
			for (let i = 0; i < 29; i++) {
				let hourHtml = ''
				const time = i === 28 ? hour : 24
				for (let s = startPoint; s < time; s++) {
					if (s % 12 === 0) {
						hourHtml = hourHtml + `<div class="date-ruler__hour--long-memory" style="margin-right: ${hourMargin}px;"><p>${s}時</p></div>`
					} else if (s % 6 === 0) {
						hourHtml = hourHtml + `<div class="date-ruler__hour--long-memory" style="margin-right: ${hourMargin}px;"><p>${s}</p></div>`
					} else if (s % 3 === 0) {
						hourHtml = hourHtml + `<div class="date-ruler__hour--short-memory" style="margin-right: ${hourMargin}px;"><p>${s}</p></div>`
					} else {
						hourHtml = hourHtml + `<div class="date-ruler__hour--short-memory" style="margin-right: ${hourMargin}px;"></div>`
					}
				}
				const endPoint = i === 28 ? hour : 24 - startPoint
				const objDate = moment(this.currentDate).add(dayCount, 'days')
				dateHtml.push(`<div class="date-ruler" style="width: ${this.widthHour * endPoint}px;">
				<p class="date-ruler__text ${i === 0 ? '' : 'date-ruler__before'}">${i === 0 ? '' : objDate.date()}<span>${i === 0 ? '' : objDate.format('(dd)')}</span></p>
				<div class="date-ruler__hour">${hourHtml}</div></div>`)
				dayCount = dayCount + 1
				startPoint = 0
			}
			const date = document.getElementById('js_dateRuler')
			date.insertAdjacentHTML('afterbegin', dateHtml.join(''))

			// NOTE: グレーマスク処理
			const rulerMask = document.getElementById('js_rulerMask')
			const maskWidth = this.posCurrentX !== 0 ? -(this.posCurrentX) : dateWidth * 28
			rulerMask.setAttribute('style', `width: ${maskWidth}px;`)
		},

		/**
		 * 痛みアイコン処理
		 */
		drawPainIcon (item, index) {
			const imagePainUrl = item.pain === '1' ? 'inp_icon_pain1_1' : item.pain === '2' ? 'inp_icon_pain2_1' : item.pain === '3' ? 'inp_icon_pain3_1' : 'inp_icon_pain4_1'
			const imagePain = new PIXI.Sprite(
				this.loader.resources['sprite'].textures[imagePainUrl]
			)
			imagePain.width = 30
			imagePain.height = 30
			// CHANGE: anchorで基準を中心に変更
			imagePain.anchor.set(0.5)
			// imagePain.x = (index * this.widthHour) + (this.stageWidth / 2) - (imagePain.width / 2)
			imagePain.x = (index * this.widthHour) + (this.stageWidth / 2)
			imagePain.y = Number.isInteger(item.hour / 2) ? 15 : 30
			imagePain.name = index	// カレントのアイコンを識別するため、nameにindexを入れておく
			this.containerPain.addChild(imagePain)
			if (item.drug !== '1') {
				const imageDrugUrl = item.drug === '2' ? 'inp_icon_drug_otc_1' : 'inp_icon_drug_pre_1'
				const imageDrug = new PIXI.Sprite(
					this.loader.resources['sprite'].textures[imageDrugUrl]
				)
				imageDrug.width = 30
				imageDrug.height = 30
				// CHANGE: anchorで基準を中心に変更
				imageDrug.anchor.set(0.5)
				// imageDrug.x = (index * this.widthHour) + (this.stageWidth / 2) - (imageDrug.width / 2)
				imageDrug.x = (index * this.widthHour) + (this.stageWidth / 2)
				imageDrug.y = Number.isInteger(item.hour / 2) ? 0 : 15
				imageDrug.name = index	// カレントのアイコンを識別するため、nameにindexを入れておく
				this.containerPain.addChild(imageDrug)
			}
		},

		/**
		 * 気象データ提供API
	 	 * @param {Boolean} isReloadPainIcon 痛みアイコンの再描画かどうかのフラグ
		 */
		setWeatherData (isReloadPainIcon) {
			this.isLoading = true
			const from = moment(this.currentDate).add(this.fromDay, 'days').unix()
			const to = moment(this.currentDate).add(this.toDay, 'days').unix()
			this.nowUnixTime = moment().unix()
			// 気象データ取得API
			const SET_WEATHER_DATA = this.SET_WEATHER_DATA({
				user_id: this.userId,
				user_token: this.userToken,
				from: from,
				to: to
			})
			// 記録データの取得API
			const SET_RECORD_DATA = this.SET_RECORD_DATA({
				user_id: this.userId,
				user_token: this.userToken,
				from: from,
				to: to
			})
			// 記録データの取得API（全期間）
			const SET_RECORD_DATA_WHOLEPERIOD = this.SET_RECORD_DATA_WHOLEPERIOD({
				user_id: this.userId,
				user_token: this.userToken,
				from: this.userData.insert_date.sec,
				to: this.nowUnixTime
			})
			let apiArray = null
			if (isReloadPainIcon) {	// 痛みアイコン再描画
				apiArray = [ SET_RECORD_DATA, SET_RECORD_DATA_WHOLEPERIOD ]
			} else {	// 通常の処理
				apiArray = [ SET_WEATHER_DATA, SET_RECORD_DATA, SET_RECORD_DATA_WHOLEPERIOD ]
			}
			Promise.all(apiArray).then(res => {
				// 「isRecordDateCheckFlag」チュートリアルモーダルを表示させるためのboolean値。実装時にコメント削除
				this.isRecordDateCheckFlag = this.recordeDataWholePeriod.records.length === 0
				// 記録データ保持
				const recordDataList = this.recordeData.records
				// NOTE: APIから取得したデータをデータ表示用に再構築する
				this.weatherDataList = this.weatherData.records.map((item, index) => {
					const recordData = recordDataList.find(recordItem => recordItem.hour.sec === item.hour)
					return {
						'city_code': item.city_code,
						'city_name': item.city_name,
						'unix_hour': item.hour,
						'year': moment.unix(item.hour).year(),
						'month': moment.unix(item.hour).month() + 1,
						'date': moment.unix(item.hour).date(),
						'weekday': moment.unix(item.hour).format('(ddd)'),
						'hour': moment.unix(item.hour).hour(),
						'temperature': item.temperature,
						'pressure': item.pressure,
						'pressure_diff': parseFloat(item.pressure_diff) < 0 ? item.pressure_diff : `+${item.pressure_diff}`,
						'pressure_diff_class': parseFloat(item.pressure_diff) < 0 ? 'is-minus' : 'is-plus',
						'pressure_level': item.pressure_level,
						'weather_code': item.weather_code,
						'pain': recordData === undefined ? null : recordData.pain,
						'drug': recordData === undefined ? null : recordData.drug,
						'comment': recordData === undefined ? null : recordData.comment,
						'painHour': item.hour
					}
				})
				if (isReloadPainIcon) {	// 痛みアイコン再描画
					this.redrawPainIcon()
				} else {	// 通常の処理
					// NOTE: 初期化
					this.totalHour = this.weatherDataList.length
					this.isNetworkErrorAlert = false
					this.initStage()
					this.drawWeathrGraph()
					this.initDateRuler()
					console.log('weatherDataList: ', this.weatherDataList)
					// NOTE: 以下の環境の際にポップアップの表示がされる
					// NOTE: 前回のポップアップから再度何日後に際表示するかは検証
					// PWAではない時、前回のポップアップの日付から30日後、ローカルストレージにポップアップした日付の登録がない時
					// CHANGE: スゴ得ではない時も条件に追加
					if (!window.matchMedia('(display-mode: standalone)').matches && (localStorage.getItem('popoupTime') === moment().format('YYYY-MM-DD') || localStorage.getItem('popoupTime') === null) && this.viewMode !== 'sugotoku') {
						// NOTE: cookieをsafariで扱う際に問題がある為localStorageに変更
						localStorage.setItem('popoupTime', moment().add(30, 'days').format('YYYY-MM-DD'))
						this.isPopoverVisible = true
					}
				}
				// NOTE: 初回（talkDataがnull）のみ実行
				if (!this.talkData) this.initTalkData()
			}).catch(error => {
				// NOTE: エラー処理
				this.isNetworkErrorAlert = true
				if (this.pixiApp) this.pixiApp.stop()
				console.log('Error setWeatherData: ', error)
			}).finally(() => {
				this.isLoading = false
			})
		},

		/**
		 * 痛み記録（鉛筆マーク）のクリックイベント
		 */
		clickPenButton () {
			// CHANGE: スゴ得は痛み記録廃止
			if (this.viewMode === 'sugotoku') {
				this.SET_VISIBLE_POPUP_TYPE('painrecord')
			} else {
				// NOTE: プロフィール登録を促す実装
				if (this.isLoading) return false	// CHANGE: Loading中にボタンが押せてしまう（.redraw-progressのpointer-events: none;が利いていない）のでローディング中は何もしないよう修正
				this.painRecordItem = this.weatherDataList[this.indexX]
				this.painRecordTime = moment(this.painRecordItem.painHour, 'X').format('YYYY年M月D日H時の記録')
				if (!this.userData.register_flg && this.isRecordDateCheckFlag) {
					// NOTE: プロフィール登録をしていない場合
					this.SET_GRAPH_DATE({ time: this.painRecordTime, hour: this.painRecordItem.painHour })
					this.isProfileModalVisible = true
					this.SET_PROFILE_TURORIAL_FLAG(true)
				} else if (this.userData.register_flg && this.isRecordDateCheckFlag && localStorage.getItem('profileComplate') === 'show') {
					// NOTE: プロフィール登録がしてあり痛み記録を一回もした事がない場合&&ローカルストレージに記録したフラグがない場合
					this.tutorialKey = 'profileComplate'
					this.tutorialImg = 'static/tutorial/scr_window_inp.png'
					this.isTutorial = true
				} else {
					// NOTE: プロフィール登録＆痛み記録が登録してある場合
					this.$refs.painRecord.clickModalOpen()
				}
			}
		},

		/**
		 * チュートリアルを閉じるクリックイベント
		 */
		clickHideTutorial () {
			if (this.tutorialKey === cmnConst.GRAPH_TUTORIAL_KEY) {
				localStorage.setItem(cmnConst.GRAPH_TUTORIAL_KEY, 'hide')
				this.isTutorial = false
			} else {
				this.isTutorial = false
				localStorage.setItem('profileComplate', 'hide')
				this.clickPenButton()
			}
		},

		/**
		 * プロフィール登録ありがとうダイアログOK
		 */
		clickProfileComplate () {
			// CHANGE: スゴ得の場合はチュートリアルを表示しないよう変更
			if (this.viewMode !== 'sugotoku') {
				this.tutorialKey = 'profileComplate'
				this.tutorialImg = 'static/tutorial/scr_window_inp.png'
				this.isTutorial = true
			}
			this.SET_PROFILE_TURORIAL_FLAG(false)
			this.isProfileComplateVisible = false
		},

		/**
		 * 時間を現在時刻にリセット
		 */
		clickResetTime () {
			if (this.posCurrentX === 0) {
				this.redraw(true)
			} else {
				// TODO: 再描画せず現在時刻に移動する処理はそのうちアニメーションにしたい
				this.pixiApp.start()
				this.moveStart()
				const deltaX = this.containerGraph.x - this.posCurrentX
				if (deltaX !== 0) {
					this.moveX({ gesture: { deltaX: -deltaX, direction: 'reset' } })
					setTimeout(() => {	// Tickerが動く前に止まるのでsetTimeOutする
						this.pixiApp.stop()
					}, 100)
				}
			}
		},

		/**
		 * pxを削除する関数
		 */
		removePx (value) {
			return Number(String(value).replace('px', ''))
		},

		/**
		 * プロフィール登録ボタンクリック
		 */
		clickProfileRegistButton () {
			if (this.viewMode === 'sugotoku') {
				this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=profile` } })
			} else {
				this.$router.push({ path: '/profile' })
			}
		},

		/**
		 * マロ＆ヒロシおしゃべりリストを取得
		 */
		initTalkData () {
			// NOTE: このAPI通信が失敗してもアプリケーションが止まらないようにする
			if (!this.talkData) {
				this.SET_TALK_DATA({
					user_id: this.userId,
					user_token: this.userToken
				}).then(res => {
					console.log('talkData: ', this.talkData)
					this.talkControler('start')
				}).catch(error => {
					// エラーが発生しても何もしない
					console.log('Error SET_TALK_DATA: ', error)
				})
			} else {
				this.talkControler('start')
			}
		},

		/**
		 * マロ＆ヒロシのおしゃべりコントローラー
		 */
		talkControler (type) {
			let action = 'normal1'	// アニメーションのアクションKEY（デフォルト：normal1）
			let talkList = []

			// NOTE: 以下のメソッド達はグローバルで利用するケースがないのでプライベートメソッドとした。必要あればグローバルにする。
			// talkDataから指定のアイテムを取得する関数
			const getTalkList = (category, detail) => {
				const records = this.talkData.records
				// 引数のcategory、detailと一致するデータを検索し、wordsの\nを<br>に置き換えて返す
				return records.filter(item => item.category === category && item.detail === detail).map(item => {
					item.words = item.words.replace(/\n/g, '<br>')
					return item
				})
			}

			// マロとヒロシのどちらをアニメーションさせるかのハンドラ
			const characterAnimationHandler = (character, action) => {
				if (character === 'M') {
					this.classMaroAnimation = action
				} else {
					this.classHiroshiAnimation = action
				}
			}

			// セリフの候補を作成
			switch (type) {
				case 'pain_register' :
					// TODO: 痛み登録促進
					// action = ''
					break
				case 'map' :
					// TODO: 全国マップ表示案内
					// action = ''
					break
				case 'pressure' :
					// TODO: 気圧コメント
					// action = ''
					break
				case 'profile_register' :
					// TODO: プロフィール登録案内
					// action = ''
					break
				case 'recording' :
					// TODO: 記録継続促進
					// action = ''
					break
				case 'start' :
					// 起動時の処理
					action = 'talk1'
					const today = moment()
					// console.log('userData.birthday(MM-DD): ', moment(this.userData.birthday).format('MM-DD'))
					// console.log('today(MM-DD): ', today.format('MM-DD'))
					// console.log('today(hour): ', today.get('hour'))
					if (moment(this.userData.birthday).format('MM-DD') === today.format('MM-DD')) {	// 誕生日
						talkList = getTalkList('anniversary', 'birthday')
					} else {	// 時候の挨拶
						const eventList = getTalkList('greeting', 'event')
						talkList = eventList.filter(item => item.option === today.format('M_D'))
						// talkList = eventList.filter(item => item.option === '9_4')	// TODO: debug用コード。不要になったら消す。
					}
					if (talkList.length === 0) {	// 時間帯の挨拶
						const alltimeList = getTalkList('greeting', 'alltime')
						const nowTime = today.get('hour')
						switch (true) {
							case nowTime >= 5 && nowTime < 11 :
								const morningList = getTalkList('greeting', 'morning')
								talkList = alltimeList.concat(morningList)
								break
							case nowTime >= 11 && nowTime < 17 :
								const daytimeList = getTalkList('greeting', 'daytime')
								talkList = alltimeList.concat(daytimeList)
								break
							case nowTime >= 17 && nowTime < 24 :
								const nightList = getTalkList('greeting', 'night')
								talkList = alltimeList.concat(nightList)
								break
							default :
								const midnightList = getTalkList('greeting', 'midnight')
								talkList = alltimeList.concat(midnightList)
								break
						}
					}
					// console.log('talkList: ', talkList)
					break
				default :
					action = 'normal1'
					break
			}

			// セリフを確定
			let words = ''
			let character = ''
			if (talkList.length === 1) {	// 候補がひとつしかない場合
				words = talkList[0].words
				character = talkList[0].character
			} else if (talkList.length > 1) {	// 候補が複数の場合
				const rand = Math.floor(Math.random() * talkList.length)
				words = talkList[rand].words
				character = talkList[rand].character
			}

			// アニメーション開始
			// console.log('action: ', action)
			// console.log('character: ', character)
			// console.log('words: ', words)

			let isTalk = true
			characterAnimationHandler(character, action)

			// アニメーション描画後の処理
			// TODO: 今後、他のアニメーションが追加されたらここの分岐を追加
			if (action === 'talk1') {	// talk1はアニメーション終了後にtalk2に切り替える
				setTimeout(() => {
					// NOTE: talk1のアニメーション中にセリフ描画が終了していたら(normal1に戻っていたら)処理しないようにする
					if (isTalk && character === 'M' && this.classMaroAnimation !== 'normal1') {
						this.classMaroAnimation = 'talk2'
					} else if (isTalk && character === 'H' && this.classHiroshiAnimation !== 'normal1') {
						this.classHiroshiAnimation = 'talk2'
					}
				}, 2200)	// 2200 = talk1のアニメーションduration
			}

			// セリフ描画
			if (words !== '') {
				const interval = 100
				let timmer = 0
				// セリフを一文字ずつ表示する
				for (let i = 0, len = words.length; i < len; i++) {
					timmer = i
					setTimeout(() => {
						this.characterTalkWords = words.substring(0, i + 1)
					}, interval * timmer)
				}
				// セリフを閉じる
				setTimeout(() => {
					isTalk = false
					// セリフを消しアニメーションをnormal1に戻す
					this.characterTalkWords = ''
					characterAnimationHandler(character, 'normal1')
				}, (interval * timmer) + 5000)	// すべて表示した5秒後に閉じる
			}
		}
	},

	beforeDestroy () {
		if (this.eventDetector) this.eventDetector.dispose()
		if (this.pixiApp) {
			this.pixiApp.stop()
			this.pixiApp.destroy(true, { children: true, texture: true, baseTexture: true })
		}

		// スゴ得の場合、コンテンツの高さをautoでemitする。
		if (this.viewMode === 'sugotoku') this.$emit('containerHeight', 'auto')
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

$graph-width: 640;
$week-width: 14336; // (w640 / 30(1画面に入る時間)) * 24h * 28day
$graph-bg-width: 15360; // (w640 / 30(1画面に入る時間)) * 24h * 30day
$graph-height: 1136;
$bg-height: 6400;
/* NOTE: z-indexの設定
 * 8: 情報バー | マロとヒロシ・痛み記録ボタン
 * 7: 日付ルーラー
 * 6: グラフ中央ライン
 * 5: 気圧メモリ
 * 4: 気圧折れ線グラフ | 痛みアイコン
 * 3: 天気ルーラー
 * 2: グラフ現在日時ライン
 * 1: 気圧警戒アラート
 * 0: 背景
 **/

.weather-graph {
	font-family: $family-secondary;
	position: relative;

	.is-small {
		font-size: $font-size-10;
	}

	&__year {
		font-size: $font-size-12;
	}

	&__date {
		font-family: $family-primary;
		font-size: $font-size-24;
	}

	&__weekday {
		padding-left: $spacing-2;
		font-size: $font-size-14;
	}

	&__hour {
		font-family: $family-primary;
		font-size: $font-size-44;

		& + span {
			padding-left: $spacing-2;
		}
	}

	&__data {
		font-size: $font-size-14;

		&:before {
			content: '';
			display: block;
			width: 0.375rem;
		}

		&:last-of-type {
			padding-top: $spacing-6;
		}

		&:first-of-type:before {
			background: $background-temperature;
		}

		&:last-of-type:before {
			background: $background-pressure-graph;
		}

		&--label {
			padding-left: $spacing-4;

			.is-hour,
			.is-comma {
				display: inline-block;
				text-align: center;
			}

			.is-hour {
				width: 17.5px;
			}
		}

		&--value {
			font-family: $family-primary;
		}

		&--value.is-minus {
			color: $text-minus;
		}

		&--value.is-plus {
			color: $text-plus;
		}
	}
}

/deep/ .popover {
	width: 100%;
	height: 100px;
	margin: 0 auto !important;
	left: 0 !important;
	right: 0 !important;
	bottom: $spacing-10 !important;
	position: absolute;
	&-mask {
		display: none;
	}
	&__content {
		width: 100%;
		max-width: 280px;
		height: 100px;
		box-shadow:0px 0px 4px -1px #000;
		margin: 0 auto;
		padding: 5px;
		position: absolute;
		left: 0 !important;
		right: 0 !important;
	}
	&__arrow {
		box-shadow:-2px 2px 2px -3px #000;
		margin: 0 auto;
		padding: 0;
		position: absolute;
		left: 0 !important;
		right: 0 !important;
		transform: translateY(6px) translateX(0px) rotate(-45deg) !important;
	}
}

.popover-addhome {
	&__cancelBarArea {
		width: 25px;
		height: 25px;
		position: absolute;
		right: 0;
		top: 0;
	}
	&__textArea {
		width: 100%;
		height: 100%;
		&--img {
			width: 20%;
			height: 100%;
			margin-top: $spacing-6;
			display: block;
			float: left;
		}
		&--text {
			width: 70%;
			margin-top: $spacing-10;
			padding: 0;
			display: block;
			float: left;
			font-size: $font-size-14;
			i {
				width: 26px;
				margin-right: $spacing-6;
				padding: 0;
				float: left;
			}
		}
		&--textWeight {
			font-weight: 500;
		}
		.android_share_icon {
			display: inline-block;
			margin-right: $spacing-4;
		}
	}
}

// インフォメーションバー
.weather-graph__information-bar {
	position: relative;
	height: 60px;
	background-color: $background-primary;
	border-bottom: 1px solid rgba($_black, .5);
	align-items: center;
	z-index: 8;

	&:before,
	&:after {
		content: '';
		position: absolute;
		right: 0;
		left: 0;
		width: 0;
		height: 0;
		margin: auto;
	}

	&:before{
		bottom: -8px;
		border-style: solid;
		border-color: rgba($_black, .5) transparent transparent transparent;
		border-width: 8px 8px 0 8px;
		z-index: 100;
	}

	&:after{
		bottom: -7px;
		border-style: solid;
		border-color: $background-primary transparent transparent transparent;
		border-width: 8px 8px 0 8px;
		z-index: 101;
	}

	&--left {
		padding-left: $spacing-10;
		line-height: $line-height-1;
		flex-grow: .9;
	}

	&--center {
		height: 100%;
		font-size: $font-size-18;
		text-align: center;
		font-weight: bold;
		flex-grow: .7;
	}

	&--right {
		padding-left: $spacing-4;
		line-height: $line-height-1;
	}
}

// グラフエリア
.weather-graph__wrapper {
	position: relative;
	width: 100%;
	max-width: 640px;
	height: #{$graph-height}px;
	margin: 0 auto;
	overflow: hidden;
}

.weather-graph__canvas {
	position: relative;
	width: 100%;
	max-width: #{$graph-width}px;
	top: 30px;
	left: 0;
	z-index: 4;

	canvas { display: block; }
}

.weather-graph__inner {
	position: absolute;
	width: #{$week-width}px;
	top: 0;
	// left: 50%;
	// transform: translateX(-50%);
}

.weather-graph__pressure {
	position: absolute;
	top: 30px;
	left: 0;
	width: 100%;
	height: 3200px; // NOTE: 3200(グラデーションの高さ)
	z-index: 5;
	&-list {
		margin: 73px 0 0 12px; // NOTE: 73 = 16(1セル高さ) * 5 - 7(アイテムの高さの半分)
		padding: 0;
	}
	&-item {
		position: relative;
		color: $text-ruler;
		font-family: $family-primary;
		margin-top: 66px; // NOTE: 66 = 16(1セル高さ) * 5 - 14(アイテムの高さ)
		line-height: $line-height-1;
		letter-spacing: -0.5px;
		list-style: none;
		&:first-child {
			margin-top: 0;
		}
		&:nth-child(2n-1) {
			&::before {
				content: '';
				position: absolute;
				background-image: linear-gradient(to right, $text-ruler, $text-ruler 4px, transparent 4px, transparent 8px);
				background-size: 8px 1px;
				background-repeat: repeat-x;
				top: 7px; // NOTE: 7(アイテムの高さの半分)
				left: 38px;
				right: 0;
				bottom: 0;
			}
		}
		&:nth-child(2n+19) {
			&::before {
				left: 30px;
			}
		}
	}
	&-scale {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
}

.weather-graph__background {
	position: absolute;
	width: #{$graph-bg-width}px;
	height: #{$bg-height}px;
	top: 0;
	transform-origin: 0 0;
	background-image: url(~@/assets/img/weather_graph_bg.svg);
	background-repeat: no-repeat;
	background-position: center top;
}

.weather-graph__date-ruler {
	position: relative;
	width: #{$week-width}px;
	height: 30px;
	display: flex;
	background-color: $background-primary;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
	/deep/ &--mask {
		position: absolute;
		height: 30px;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
	}
	z-index: 7;
}

/deep/ .date-ruler {
	height: 30px;
	background-color: $background-primary;
	&__text {
		width: 100%;
		margin: 0 auto;
		height: 18px;
		background-color: $date-ruler-background;
		padding-left: $spacing-4;
		font-size: $font-size-12;
		font-family: $family-primary;
		position: relative;
		display: block;
		span {
			font-size: $font-size-10;
		}
	}
	&__before:before{
		display: block;
		position: absolute;
		top: 0;
		left: -2px;
		border-right: solid 4px $background-primary;
		height: 18px;
		content: '';
	}
	&__hour {
		width: 100%;
		display: flex;
		margin: 0 auto;
		&--long-memory {
			width: 1px;
			height: 6px;
			background-color: $_black;
			margin-top: $spacing-6;
			p {
				width: 25px;
				text-align: center;
				font-size: $font-size-9;
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				top: -20px;
				font-family: $family-primary;
			}
		}
		&--short-memory {
			width: 1px;
			height: 2px;
			background-color: $_black;
			margin-top: $spacing-10;
			p {
				width: 15px;
				text-align: center;
				font-size: $font-size-8;
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				top: -18px;
				font-family: $family-primary;
			}
		}
	}
}

/deep/ .weather-graph__pressure-warning {
	position: relative;
	width: 100%;
	z-index: 1;
	&-list {
		position: absolute;
		display: flex;	// CHANGE: inline-blockだとandroidで段落ちするのでflexに変更
		width: 100%;
		margin: 0;
		padding: 0;
	}
	&-item {
		position: relative;
		// display: inline-block;	// CHANGE: inline-blockだとandroidで段落ちするのでflexに変更
		width: 20vw;
		list-style: none;
		overflow: hidden;
	}
	&-area {
		position: absolute;
		left: 50%;
	}
	&-icon {
		position: absolute;
		left: 50%;
		&.warning_icon1 {
			top: 50%;
		}
		&.warning_icon2 {
			top: calc(50% - 10px);
		}
		&.warning_icon3 {
			top: calc(50% - 20px);
		}
		&.warning_icon4 {
			top: calc(50% - 30px);
		}
	}
}

/deep/ .weather-graph__weather-ruler {
	position: relative;
	width: 100%;
	height: 30px;
	z-index: 3;
	&-list {
		position: absolute;
		display: flex;	// CHANGE: inline-blockだとandroidで段落ちするのでflexに変更
		width: 100%;
		// top: -2px;
		top: 3px;	// CHANGE: inline-blockからflexに変更した調整
		margin: 0;
		padding: 0;
	}
	&-item {
		position: relative;
		// display: inline-block;	// CHANGE: inline-blockだとandroidで段落ちするのでflexに変更
		width: 10vw;
		list-style: none;
	}
	&-temperature {
		display: block;
		font-size: $font-size-8;
		text-align: center;
		&.is-past {
			color: $text-bold;
		}
		&.is-future {
			color: $text-minus;
		}
	}
	&-code {
		position: absolute;
		top: -12px;
		left: 50%;
		transform: translateX(-50%) scale(.4);
	}
}

.weather-graph__current-line {
	display: none;
	position: absolute;
	width: 2px;
	height: #{$graph-height - 30}px;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	background-color: rgba($current-line, .4);
	z-index: 2;
	&:before {
		content: '';
		display: block;
		border-top: 10.5px solid $current-line;
		border-right: 7px solid transparent;
		border-bottom: 10.5px solid transparent;
		border-left: 7px solid transparent;
		left: 50%;
		transform: translateX(-50%);
	}
}

.weather-graph__fixed {
	position: fixed;
	top: 60px;
	width: 100%;
	height: #{$graph-height - 60}px;
}

.weather-graph__center-dot {
	display: block;
	position: absolute;
	top: calc(50% + 16.5px);	// 16.5pxは調整値
	left: 50%;
	width: 9px;
	height: 9px;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	background-color: $temperature-line;
}

.weather-graph__center-line {
	display: block;
	position: absolute;
	top: 30px;	// ルーラーの高さ
	left: 50%;
	width: 3px;
	height: 100%;
	transform: translateX(-50%);
	background-color: rgba($temperature-center-line, .3);
	z-index: 6;
}

.weather-graph__character {
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 100%;
	height: 120px;
	border-bottom: 6px solid $primary;
	z-index: 8;

	.h_animation,
	.weather-graph__record--pain,
	.m_animation {
		position: absolute;
	}
	.h_animation,
	.m_animation {
		display: block;
		width: 480px;
		height: 360px;
	}
	.h_animation {
		bottom: -6px;	// 元画像の余白分
		left: 0;
		transform-origin: bottom left;
		transform: scale(0.3334);
	}
	.m_animation {
		bottom: -6px;	// 元画像の余白分
		right: 0;
		transform-origin: bottom right;
		transform: scale(0.3334);
	}
	.weather-graph__record--pain {
		bottom: -2px;	// 元画像の余白分
		left: 50%;
		margin: 0;
		transform-origin: bottom center;
		transform: translateX(-50%) scale(0.3334);
	}

	&--talk {
		position: absolute;
		width: 58.75%;	// 58.75% = 188px / 320px
		height: 0;
		padding-top: 21.875%;	// 21.875% = 70px / 320px
		margin: 0;
		bottom: 70px;
		transform: translateX(-50%);
		box-sizing: border-box;

		& > span {
			position: absolute;
			display: inline-block;
			width: 78.7234%;	// 78.7234% = 148px / 188px
			top: calc(50% - 6.383%);	// 6.383%（吹き出し矢印の余白分） = 12px / 188px
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: $font-size-12;
			font-family: $family-primary;
			line-height: $line-height-1;
		}

		&.H {
			left: calc(50% - 6px);
			background: url(~@/assets/img/balloon_l.png) no-repeat 0 0;
			background-size: 100% auto;
		}

		&.M {
			left: calc(50% + 6px);
			background: url(~@/assets/img/balloon_r.png) no-repeat 0 0;
			background-size: 100% auto;
		}
	}
}

@media screen and (min-width: 480px) {
	.weather-graph__character--talk > span {
		line-height: $line-height;
	}
}

// ヒロシのアニメーション
.h_animation {
	&.normal1 {
		background: url(~@/assets/img/sprite_h_normal1.png) no-repeat 0 0;
		animation: normal1 3.2s steps(32) infinite;
	}

	&.talk1 {
		background: url(~@/assets/img/sprite_h_talk1.png) no-repeat 0 0;
		animation: talk1 2.2s steps(22) infinite;
	}

	&.talk2 {
		background: url(~@/assets/img/sprite_h_talk2.png) no-repeat 0 0;
		animation: h_talk2 2.3s steps(23) infinite;
	}
}

// マロのアニメーション
.m_animation {
	&.normal1 {
		background: url(~@/assets/img/sprite_m_normal1.png) no-repeat 0 0;
		animation: normal1 3.2s steps(32) infinite;
	}
	&.talk1 {
		background: url(~@/assets/img/sprite_m_talk1.png) no-repeat 0 0;
		animation: talk1 2.2s steps(22) infinite;
	}
	&.talk2 {
		background: url(~@/assets/img/sprite_m_talk2.png) no-repeat 0 0;
		animation: m_talk2 1.8s steps(18) infinite;
	}
}

@keyframes normal1 {
  to {
    background-position: 0 -11520px;
  }
}

@keyframes talk1 {
  to {
    background-position: 0 -7920px;
  }
}

@keyframes h_talk2 {
  to {
    background-position: 0 -8280px;
  }
}

@keyframes m_talk2 {
  to {
    background-position: 0 -6480px;
  }
}

// プロフィール登録モーダル
.profile-modal {
	width: 80%;
	height: 400px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto $spacing-20;
	border-radius: 10px;
	background-color: $_white;
	&__box {
		width: 80%;
		margin: 0 auto;
		&--close {
			position: absolute;
			left: 0;
			top: 0;
		}
		&--title {
			color: $profile-modal-title;
			font-size: $font-size-24;
			font-family: $family-primary;
		}
		> span {
			width: 100%;
		}
		&--text {
			color: $profile-modal-text;
			font-size: $font-size-14;
			text-align: left;
		}
	}
}

// トランジション
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

// スプライト画像
/deep/ .sprite-weathergraph {
	background-image: url(~@/assets/img/sprite_weathergraph.png);
	background-repeat: no-repeat;
	display: block;

	&.btn_pen {
		width: 258px;
		height: 258px;
		background-position: 0 0;
	}

	&.btn_pen_on {
		width: 258px;
		height: 258px;
		background-position: 0 -258px;
	}

	&.h_normal1_0001 {
		width: 480px;
		height: 360px;
		background-position: 0 -516px;
	}

	&.inp_icon_drug_otc_1 {
		width: 99px;
		height: 99px;
		background-position: 0 -876px;
	}

	&.inp_icon_drug_pre_1 {
		width: 99px;
		height: 99px;
		background-position: 0 -975px;
	}

	&.inp_icon_pain1_1 {
		width: 99px;
		height: 99px;
		background-position: 0 -1074px;
	}

	&.inp_icon_pain2_1 {
		width: 99px;
		height: 99px;
		background-position: 0 -1173px;
	}

	&.inp_icon_pain3_1 {
		width: 99px;
		height: 99px;
		background-position: 0 -1272px;
	}

	&.inp_icon_pain4_1 {
		width: 99px;
		height: 99px;
		background-position: 0 -1371px;
	}

	&.m_normal1_0022 {
		width: 480px;
		height: 360px;
		background-position: 0 -1470px;
	}

	&.warning_area1 {
		width: 180px;
		height: 1050px;
		background-position: 0 -1830px;
	}

	&.warning_area2 {
		width: 180px;
		height: 1050px;
		background-position: 0 -2880px;
	}

	&.warning_area3 {
		width: 180px;
		height: 1050px;
		background-position: 0 -3930px;
	}

	&.warning_area4 {
		width: 180px;
		height: 1050px;
		background-position: 0 -4980px;
	}

	&.warning_icon1 {
		width: 165px;
		height: 165px;
		background-position: 0 -6030px;
	}

	&.warning_icon2 {
		width: 165px;
		height: 165px;
		background-position: 0 -6195px;
	}

	&.warning_icon3 {
		width: 165px;
		height: 165px;
		background-position: 0 -6360px;
	}

	&.warning_icon4 {
		width: 165px;
		height: 165px;
		background-position: 0 -6525px;
	}

	&.wicon_ame {
		width: 69px;
		height: 69px;
		background-position: 0 -6690px;
	}

	&.wicon_ame_for_list {
		width: 46px;
		height: 46px;
		background-position: 0 -6759px;
	}

	&.wicon_ame_old {
		width: 69px;
		height: 69px;
		background-position: 0 -6805px;
	}

	&.wicon_hare {
		width: 69px;
		height: 69px;
		background-position: 0 -6874px;
	}

	&.wicon_haremax {
		width: 69px;
		height: 69px;
		background-position: 0 -6943px;
	}

	&.wicon_haremax_old {
		width: 69px;
		height: 69px;
		background-position: 0 -7012px;
	}

	&.wicon_haremoon {
		width: 69px;
		height: 69px;
		background-position: 0 -7081px;
	}

	&.wicon_haremoon_old {
		width: 69px;
		height: 69px;
		background-position: 0 -7150px;
	}

	&.wicon_hare_for_list {
		width: 46px;
		height: 46px;
		background-position: 0 -7219px;
	}

	&.wicon_hare_old {
		width: 69px;
		height: 69px;
		background-position: 0 -7265px;
	}

	&.wicon_kumori {
		width: 69px;
		height: 69px;
		background-position: 0 -7334px;
	}

	&.wicon_kumori_old {
		width: 69px;
		height: 69px;
		background-position: 0 -7403px;
	}

	&.wicon_yuki {
		width: 69px;
		height: 69px;
		background-position: 0 -7472px;
	}

	&.wicon_yuki_old {
		width: 69px;
		height: 69px;
		background-position: 0 -7541px;
	}
}

.sprite-popoup {
	background-image: url(~@/assets/img/sprite_popoup.png);
	background-repeat: no-repeat;
	display: block;
	&.app_icon {
		width: 144px;
		height: 144px;
		background-position: -40px -40px;
		transform: scale(0.8);
		zoom: 0.5;
	}

	&.btn_inp_x {
		width: 25px;
		height: 25px;
		background-position: -74px -297px;
		transform: scale(0.4);
	}

	&.share_icon {
		width: 26px;
		height: 36px;
		background-position: -40px -434px;
		transform: scale(0.9);
		zoom: 0.5;
	}
}

// プロフィール登録（モーダル）スプライト
.profile-modal__sprite {
	background-image: url(~@/assets/img/sprite_profileModal.png);
	background-repeat: no-repeat;
	display: block;
}

.profile-modal__sprite.sprite-btn_inp_x {
	width: 90px;
	height: 90px;
	background-position: -50px -50px;
	transform: scale(1);
	zoom: 0.4;
}

.profile-modal__sprite.sprite-btn_inp_x_on {
	width: 90px;
	height: 90px;
	background-position: -50px -240px;
	transform: scale(1);
	zoom: 0.4;
}

.profile-modal__sprite.sprite-btn_note {
	width: 687px;
	height: 281px;
	background-position: -50px -430px;
	transform: scale(1);
	zoom: 0.3;
	margin: 0 auto;
	padding: 0;
	border-bottom: none;
}

.profile-modal__sprite.sprite-btn_note_on {
	width: 687px;
	height: 281px;
	background-position: -50px -811px;
	transform: scale(1);
	zoom: 0.3;
	margin: 0 auto;
	padding: 0;
	border-bottom: none;
}

.profile-modal__sprite.sprite-note_img {
	width: 767px;
   height: 288px;
	background-position: -50px -1192px;
	transform: scale(1);
	zoom: 0.3;
	margin: 0 auto;
	padding: 0;
	border-bottom: none;
}

</style>
