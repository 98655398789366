<template>
	<div>
		<!-- 痛み記録ポップアップ -->
		<v-ons-modal :visible="modalVisible" animation="fade">
			<div class="pain-form">
				<!-- 閉じるボタン -->
				<div class="pain-form__close" @click="clickModalClose" @touchstart="classCloseBtn = 'btn_inp_x_on'" @touchend="classCloseBtn = 'btn_inp_x'">
					<i class="sprite-painrecord" :class="classCloseBtn" />
				</div>
				<!-- タイトル表示 -->
				<div class="pain-form__title">
					<h2>{{ painRecordTime }}</h2>
				</div>
				<div class="pain-form__select">
					<!-- 痛み選択 -->
					<div class="pain-form__select--painBox">
						<div class="pain-form__select--painBox-title">
							<i class="sprite-painrecord teruneko_shadow" />
							<p>痛みは？</p>
						</div>
						<div class="pain-form__select--painBox-iconBox">
							<div class="pain-form__select--painBox-iconBox_arrow">
								<i class="sprite-painrecord inp_pain_arrow" />
							</div>
							<div class="pain-form__select--painBox-iconBox_icon">
								<div @click="clickPainSelect(1)">
									<i class="sprite-painrecord" :class="[classPainLevel1Scale, classPainLevel1Img]" />
								</div>
								<div @click="clickPainSelect(2)">
									<i class="sprite-painrecord" :class="[classPainLevel2Scale, classPainLevel2Img]" />
								</div>
								<div @click="clickPainSelect(3)">
									<i class="sprite-painrecord" :class="[classPainLevel3Scale, classPainLevel3Img]" />
								</div>
								<div @click="clickPainSelect(4)">
									<i class="sprite-painrecord" :class="[classPainLevel4Scale, classPainLevel4Img]" />
								</div>
							</div>
						</div>
					</div>
					<!-- くすり選択 -->
					<div class="pain-form__select--dragBox">
						<div class="pain-form__select--dragBox-title">
							<i class="sprite-painrecord teruneko_shadow" />
							<p>くすり飲んだ？</p>
						</div>
						<div class="pain-form__select--dragBox-iconBox">
							<div class="pain-form__select--dragBox-iconBox_icon">
								<div @click="clickDrugSelect(1)">
									<i class="sprite-painrecord" :class="[classDrugBg1Img, classDrugLevel1Scale]" />
									<i class="sprite-painrecord" :class="[classDrugLevel1Img, classDrugLevel1Scale]" />
									<p>なし</p>
								</div>
								<div @click="clickDrugSelect(2)">
									<i class="sprite-painrecord" :class="[classDrugBg2Img, classDrugLevel2Scale]" />
									<i class="sprite-painrecord" :class="[classDrugLevel2Img, classDrugLevel2Scale]" />
									<p>市販薬</p>
								</div>
								<div @click="clickDrugSelect(3)">
									<i class="sprite-painrecord" :class="[classDrugBg3Img, classDrugLevel3Scale]" />
									<i class="sprite-painrecord" :class="[classDrugLevel3Img, classDrugLevel3Scale]" />
									<p>処方薬</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- メモ処理 -->
				<div class="pain-form__textarea">
					<dl class="pain-form__textarea--titleBox">
						<dt>メモ</dt>
						<dd :style="computedTypedText >= 0 ? {} : { color: '#E23B49' }">{{ computedTypedText }}</dd>
					</dl>
					<textarea class="textarea" rows="3" v-model="typedText"></textarea>
				</div>
				<!-- 保存ボタン、削除ボタン -->
				<div class="pain-form__buttonArea">
					<div class="pain-form__buttonArea--left" @click="clickSaveButton" @touchstart="classOkBtn = 'btn_inp_ok_on'" @touchend="classOkBtn = 'btn_inp_ok'">
						<i class="sprite-painrecord" :class="classOkBtn" />
					</div>
					<div class="pain-form__buttonArea--right" @click="isVisibleDeleteFlag = true" @touchstart="classClearBtn = 'btn_inp_clear_on'" @touchend="classClearBtn = 'btn_inp_clear'">
						<i class="sprite-painrecord" :class="classClearBtn" />
					</div>
				</div>
			</div>
		</v-ons-modal>
		<!-- 文字数超過アラート -->
		<v-ons-alert-dialog modifier="rowfooter"
			:title="'エラー'"
			:footer="{
				OK: () => isVisibleSaveFlag = false
			}"
			:visible.sync="isVisibleSaveFlag"
		>
			{{ 'コメントは140文字以内で入力してください' }}
		</v-ons-alert-dialog>
		<!-- 削除確認アラート -->
		<v-ons-alert-dialog modifier="rowfooter"
			:title="'確認'"
			:footer="{
				いいえ: () => isVisibleDeleteFlag = false,
				はい: () => {
					isVisibleDeleteFlag = false,
					clickDeleteButton()
				}
			}"
			:visible.sync="isVisibleDeleteFlag"
		>
			{{ `${painRecordTime}を削除してもよろしいですか？` }}
		</v-ons-alert-dialog>
	</div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'PainRecord',
	data () {
		return {
			modalVisible: false,
			typedText: '',
			painItem: '',
			drugItem: '',
			isVisibleSaveFlag: false,
			isVisibleDeleteFlag: false,
			classCloseBtn: 'btn_inp_x',
			classPainLevel1Img: 'pain_level_1_off',
			classPainLevel2Img: 'pain_level_2_off',
			classPainLevel3Img: 'pain_level_3_off',
			classPainLevel4Img: 'pain_level_4_off',
			classPainLevel1Scale: 'is-medium',
			classPainLevel2Scale: 'is-medium',
			classPainLevel3Scale: 'is-medium',
			classPainLevel4Scale: 'is-medium',
			classDrugLevel1Img: 'drug_level_1_off',
			classDrugLevel2Img: 'drug_level_2_off',
			classDrugLevel3Img: 'drug_level_3_off',
			classDrugBg1Img: 'pain_drug_shadow_off',
			classDrugBg2Img: 'pain_drug_shadow_off',
			classDrugBg3Img: 'pain_drug_shadow_off',
			classDrugLevel1Scale: 'is-large',
			classDrugLevel2Scale: 'is-large',
			classDrugLevel3Scale: 'is-large',
			classOkBtn: 'btn_inp_ok',
			classClearBtn: 'btn_inp_clear'
		}
	},
	props: {
		// 記録の日時
		painRecordTime: {
			type: String,
			default: ''
		},
		// 痛み記録データの対象アイテム
		painRecordItem: {
			type: Object,
			default: null
		}
	},
	mounted () {
	},
	computed: {
		...mapGetters('common', ['userId', 'userToken']),
		// コメント入力
		computedTypedText () {
			return 140 - Number(this.typedText.length)
		}
	},
	methods: {
		// map Vuex actions
		...mapActions('weatherGraph', ['updateRecordData', 'deleteRecordData']),

		// 痛み選択
		clickPainSelect (e) {
			const selectItem = !e ? 1 : e
			// NOTE: class切り替え処理
			this.classPainLevel1Img = selectItem === 1 ? 'pain_level_1' : 'pain_level_1_off'
			this.classPainLevel2Img = selectItem === 2 ? 'pain_level_2' : 'pain_level_2_off'
			this.classPainLevel3Img = selectItem === 3 ? 'pain_level_3' : 'pain_level_3_off'
			this.classPainLevel4Img = selectItem === 4 ? 'pain_level_4' : 'pain_level_4_off'
			this.classPainLevel1Scale = selectItem === 1 ? 'is-large' : e === 2 ? 'is-medium' : e === 3 ? 'is-small' : 'is-tiny'
			this.classPainLevel2Scale = selectItem === 1 ? 'is-medium' : e === 2 ? 'is-large' : e === 3 ? 'is-medium' : 'is-small'
			this.classPainLevel3Scale = selectItem === 1 ? 'is-small' : e === 2 ? 'is-medium' : e === 3 ? 'is-large' : 'is-medium'
			this.classPainLevel4Scale = selectItem === 1 ? 'is-tiny' : e === 2 ? 'is-small' : e === 3 ? 'is-medium' : 'is-large'
			this.painItem = String(selectItem)
		},

		// 薬選択
		clickDrugSelect (e) {
			const selectItem = !e ? 1 : e
			// NOTE: class切り替え処理
			this.classDrugLevel1Img = selectItem === 1 ? 'drug_level_1' : 'drug_level_1_off'
			this.classDrugLevel2Img = selectItem === 2 ? 'drug_level_2' : 'drug_level_2_off'
			this.classDrugLevel3Img = selectItem === 3 ? 'drug_level_3' : 'drug_level_3_off'
			this.classDrugBg1Img = selectItem === 1 ? 'pain_drug_shadow' : 'pain_drug_shadow_off'
			this.classDrugBg2Img = selectItem === 2 ? 'pain_drug_shadow' : 'pain_drug_shadow_off'
			this.classDrugBg3Img = selectItem === 3 ? 'pain_drug_shadow' : 'pain_drug_shadow_off'
			this.classDrugLevel1Scale = selectItem === 1 ? 'is-large' : 'is-medium'
			this.classDrugLevel2Scale = selectItem === 2 ? 'is-large' : 'is-medium'
			this.classDrugLevel3Scale = selectItem === 3 ? 'is-large' : 'is-medium'
			this.drugItem = String(selectItem)
		},

		// 保存
		clickSaveButton () {
			// NOTE: 140文字以上の時はアラートを表示
			this.isVisibleSaveFlag = !(Number(this.typedText.length) <= 140)
			if (!this.isVisibleSaveFlag) {
				this.updateRecordData({
					user_id: this.userId,
					user_token: this.userToken,
					pain: this.painItem,
					drug: this.drugItem,
					comment: this.typedText,
					hour: this.painRecordItem.painHour
				}).then(res => {
					this.clickModalClose()
					this.$emit('reload')
				}).catch(error => {
					console.log(error)
				})
			}
		},

		// 削除
		clickDeleteButton () {
			this.deleteRecordData({
				user_id: this.userId,
				user_token: this.userToken,
				hour: this.painRecordItem.painHour
			}).then(res => {
				this.clickModalClose()
				this.$emit('reload')
			}).catch(error => {
				console.log(error)
			})
		},

		// モーダルを開く
		clickModalOpen () {
			this.modalVisible = true
			// NOTE: 表示の際にアニメーションの為、時間を遅らせる
			setTimeout(() => {
				this.clickPainSelect(Number(this.painRecordItem.pain))
				this.clickDrugSelect(Number(this.painRecordItem.drug))
				if (this.painRecordItem.comment !== null) this.typedText = this.painRecordItem.comment
			}, 300)
		},

		// モーダルを閉じる
		clickModalClose () {
			this.modalVisible = false
			// 初期化
			this.typedText = ''
			this.classPainLevel1Img = 'pain_level_1_off'
			this.classPainLevel2Img = 'pain_level_2_off'
			this.classPainLevel3Img = 'pain_level_3_off'
			this.classPainLevel4Img = 'pain_level_4_off'
			this.classPainLevel1Scale = 'is-medium'
			this.classPainLevel2Scale = 'is-medium'
			this.classPainLevel3Scale = 'is-medium'
			this.classPainLevel4Scale = 'is-medium'
			this.classDrugLevel1Img = 'drug_level_1_off'
			this.classDrugLevel2Img = 'drug_level_2_off'
			this.classDrugLevel3Img = 'drug_level_3_off'
			this.classDrugBg1Img = 'pain_drug_shadow_off'
			this.classDrugBg2Img = 'pain_drug_shadow_off'
			this.classDrugBg3Img = 'pain_drug_shadow_off'
			this.classDrugLevel1Scale = 'is-large'
			this.classDrugLevel2Scale = 'is-large'
			this.classDrugLevel3Scale = 'is-large'
		}
	}
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variable";

.pain-form {
	width: 80%;
	height: 490px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto $spacing-20;
	border-radius: 10px;
	background-color: $_white;

	&__close {
		position: absolute;
		left: 0;
		width: 100%;
		text-align: left;
		i {
			padding: 0;
			margin: $spacing-10 0 0 $spacing-10;
		}
	}

	&__title {
		width: 100%;
		height: 45px;
		padding-top: $spacing-10;
		text-align: center;
		h2 {
			padding: 0;
			color: $text-bold;
			font-size: $font-size-14;
			font-weight: normal;
		}
	}

	&__select {
		width: 90%;
		margin: 0 auto;
		border: solid $background-list;
		border-width: 1px 0 1px 0;
		&--painBox {
			width: 100%;
			height: 100px;
			border: solid $primary;
			border-width: 0 0 0 5px;
			margin: $spacing-20 0 $spacing-20 0;
			&-title {
				width: 100%;
				height: 40px;
				i {
					width: 20%;
					height: 30px;
					display: block;
					float: left;
					background-position: 1px 2px;
				}
				p {
					width: 75%;
					height: 30px;
					display: block;
					float: left;
					margin: 0 0 0 0;
					color: $background-pressure-note;
					font-family: $family-primary;
					font-size: $font-size-24;
					text-align: left;
					font-weight: normal;
				}
			}
			&-iconBox {
				width: 100%;
				height: 60px;
				position: relative;
				&_arrow {
					width: 100%;
					height: 40px;
					position: absolute;
					margin-top: $spacing-10;
				}
				&_icon {
					width: 90%;
					height: 60px;
					display: flex;
					position: absolute;
					top: -2px;
					left: 0;
					right: 0;
					margin: 0 auto;
					div {
						width: 25%;
						height: 60px;
						display: inline-block;
						align-items: center;
					}
				}
			}
		}
		&--dragBox {
			width: 100%;
			height: 100px;
			border: solid $primary;
			border-width: 0 0 0 5px;
			margin: $spacing-20 0 $spacing-20 0;
			&-title {
				width: 100%;
				height: 40px;
				i {
					width: 20%;
					height: 30px;
					display: block;
					float: left;
					background-position: 1px 2px;
				}
				p {
					width: 75%;
					height: 30px;
					display: block;
					float: left;
					margin: 0 0 0 0;
					color: $background-pressure-note;
					font-family: $family-primary;
					font-size: $font-size-24;
					text-align: left;
					font-weight: normal;
				}
			}
			&-iconBox {
				width: 100%;
				height: 60px;
				position: relative;
				&_icon {
					width: 80%;
					height: 60px;
					display: flex;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
					div {
						width: 33%;
						height: 60px;
						margin: 0 auto;
						display: inline-block;
						align-items: center;
						position: relative;
						i {
							width: 33%;
							height: 60px;
							margin: 0 auto;
						}
						i:nth-child(2) {
							position: absolute;
							top: 20%;
							left: 21%;
							margin: 0 auto;
						}
						p {
							position: absolute;
							color: $text-bold;
							left: 0;
							right: 0;
							bottom: -10px;
							margin: 0 auto;
						}
					}
				}
			}
		}
	}

	&__textarea {
		width: 100%;
		&--titleBox {
			width: 100%;
			height: 20px;
			margin: $spacing-10 0 0 0;
			position: relative;
			dt,dd {
				position: absolute;
				margin: 0;
				padding: 0;
				color: $_black;
				bottom: 0;
			}
			dt {
				left: $spacing-10;
				font-family: $family-primary;
			}
			dd {
				font-size: $font-size-10;
				right: $spacing-10;
			}
		}
		/deep/ textarea {
			width: 100%;
			border: solid $background-list;
			border-width: 1px 0 1px 0;
			border-radius: 0;
		}
	}

	&__buttonArea {
		width: 100%;
		height: 100%;
		position: relative;
		&--left {
			width: 70px;
			height: 70px;
			left: 0;
			right: 0;
			top: 5px;
			bottom: 0;
			margin: 0 auto;
			position: absolute;
			i {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: 0 auto;
			}
		}
		&--right {
			width: 40px;
			height: 40px;
			right: 20px;
			top: 20px;
			position: absolute;
		}
	}
}

// 大
.is-large {
	transform: scale(1);
}
// 中
.is-medium {
	transform: scale(0.7);
}
// 小
.is-small {
	transform: scale(0.5);
}
// 極小
.is-tiny {
	transform: scale(0.3);
}

// 痛み記録モーダル画像
.sprite-painrecord {
	background-image: url('../../assets/img/sprite_painFrom.png');
	background-repeat: no-repeat;
	display: block;
}

.sprite-painrecord.btn_inp_clear {
	width: 120px;
	height: 120px;
	background-position: -20px -20px;
	zoom: 0.6;
	transform: scale(0.5);
	transform-origin: 0 0;
}

.sprite-painrecord.btn_inp_clear_on {
	width: 120px;
	height: 120px;
	background-position: -20px -180px;
	zoom: 0.6;
	transform: scale(0.5);
	transform-origin: 0 0;
}

.sprite-painrecord.btn_inp_ok {
	width: 210px;
	height: 210px;
	background-position: -20px -340px;
	zoom: 0.4;
	transform: scale(0.8);
	transform-origin: 0 0;
}

.sprite-painrecord.btn_inp_ok_on {
	width: 210px;
	height: 210px;
	background-position: -20px -590px;
	zoom: 0.4;
	transform: scale(0.8);
	transform-origin: 0 0;
}

.sprite-painrecord.btn_inp_x {
	width: 90px;
	height: 90px;
	background-position: -20px -1090px;
	zoom: 0.6;
	transform: scale(0.5);
	transform-origin: 0 0;
}

.sprite-painrecord.btn_inp_x_on {
	width: 90px;
	height: 90px;
	background-position: -20px -1220px;
	zoom: 0.6;
	transform: scale(0.5);
	transform-origin: 0 0;
}

.sprite-painrecord.inp_pain_arrow {
	width: 100%;
	height: 40px;
	background-position: 100% 97.39%;
	transform-origin: 0 0;
	transform: scale(1.04);
	zoom:  0.8;
}

.sprite-painrecord.teruneko_shadow {
	width: 47px;
	height: 76px;
	background-position: -22px -5819px;
	transform: scale(0.5);
	transform-origin: top center;
}

.sprite-painrecord.pain_level_1 {
	width: 270px;
	height: 270px;
	background-position: -20px -2765px;
	transform-origin: center center;
	zoom:  0.22;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.pain_level_1_off {
	width: 270px;
	height: 270px;
	background-position: -20px -3075px;
	transform-origin: center center;
	zoom:  0.22;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.pain_level_2 {
	width: 270px;
	height: 270px;
	background-position: -20px -3388px;
	transform-origin: center center;
	zoom:  0.22;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.pain_level_2_off {
	width: 270px;
	height: 270px;
	background-position: -20px -3695px;
	transform-origin: center center;
	zoom:  0.22;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.pain_level_3 {
	width: 270px;
	height: 270px;
	background-position: -20px -4006px;
	transform-origin: center center;
	zoom:  0.22;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.pain_level_3_off {
	width: 270px;
	height: 270px;
	background-position: -20px -4315px;
	transform-origin: center center;
	zoom:  0.22;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.pain_level_4 {
	width: 270px;
	height: 270px;
	background-position: -20px -4626px;
	transform-origin: center center;
	zoom:  0.22;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.pain_level_4_off {
	width: 270px;
	height: 270px;
	background-position: -20px -4935px;
	transform-origin: center center;
	zoom:  0.22;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.drug_level_1 {
	width: 195px;
	height: 195px;
	background-position: -20px -1352px;
	transform-origin: center center;
	zoom:  0.2;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.drug_level_1_off {
	width: 195px;
	height: 195px;
	background-position: -20px -1588px;
	transform-origin: center center;
	zoom:  0.2;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.drug_level_2 {
	width: 195px;
	height: 195px;
	background-position: -20px -1824px;
	transform-origin: center center;
	zoom:  0.2;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.drug_level_2_off {
	width: 195px;
	height: 195px;
	background-position: -20px -2058px;
	transform-origin: center center;
	zoom:  0.2;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.drug_level_3 {
	width: 195px;
	height: 195px;
	background-position: -20px -2296px;
	transform-origin: center center;
	zoom:  0.2;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.drug_level_3_off {
	width: 195px;
	height: 195px;
	background-position: -20px -2532px;
	transform-origin: center center;
	zoom:  0.2;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.pain_drug_shadow {
	width: 195px;
	height: 195px;
	background-position: -20px -5250px;
	transform-origin: center center;
	zoom:  0.33;
	transform: scale(1);
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.sprite-painrecord.pain_drug_shadow_off {
	width: 195px;
	height: 195px;
	background-position: -20px -5480px;
	transform-origin: center center;
	zoom:  0.33;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}
</style>
